// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // General variables
  keyword: "",
  locationFormattedAddress: "",
  locationLatitude: null,
  locationLongitude: null,
  locationCity: null,
  locationState: null,
  locationCountry: null,
  locationCountryIso: null,
  location: "",
  location_data: "",
  premiumOnly: false,
  photos: false,
  viewed: false,
  unviewed: false,
  favorited: false,
  favoritedMe: false,
  minAge: 18,
  maxAge: 60,
  // minHeight: 130,
  // maxHeight: 220,
  lifestyleData: [],
  bodyTypeData: [],
  ethnicityData: [],
  eyeColorData: [],
  hairColorData: [],
  smokingData: [],
  drinkingData: [],
  relationshipStatusData: [],
  educationData: [],
  tor: [],

  // Utility variables
  lifestyleSelected: [],
  bodyTypeSelected: [],
  ethnicitySelected: [],
  eyeColorSelected: [],
  hairColorSelected: [],
  smokingSelected: [],
  drinkingSelected: [],
  relationshipStatusSelected: [],
  educationSelected: [],
  torSelected: 0,

  isFiltered: false,
  selectedFilters: [],
  triggerSearchFilteredPassthrough: {},
};

export const searchFilterSlice = createSlice({
  name: "searchFilter",
  initialState,
  reducers: {
    // General Functions
    updateKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    updateLocationFormattedAddress: (state, action) => {
      state.locationFormattedAddress = action.payload;
    },
    updateLocationLatitude: (state, action) => {
      state.locationLatitude = action.payload;
    },
    updateLocationLongitude: (state, action) => {
      state.locationLongitude = action.payload;
    },
    updateLocationCity: (state, action) => {
      state.locationCity = action.payload;
    },
    updateLocationState: (state, action) => {
      state.locationState = action.payload;
    },
    updateLocationCountry: (state, action) => {
      state.locationCountry = action.payload;
    },
    updateLocationCountryIso: (state, action) => {
      state.locationCountryIso = action.payload;
    },
    updateLocation: (state, action) => {
      state.location = action.payload;
    },
    updateLocationData: (state, action) => {
      state.location_data = action.payload;
    },
    updatePremiumOnly: (state, action) => {
      state.premiumOnly = action.payload;
    },
    updatePhotos: (state, action) => {
      state.photos = action.payload;
    },
    updateViewed: (state, action) => {
      state.viewed = action.payload;
    },
    updateUnviewed: (state, action) => {
      state.unviewed = action.payload;
    },
    updateFavorited: (state, action) => {
      state.favorited = action.payload;
    },
    updateFavoritedMe: (state, action) => {
      state.favoritedMe = action.payload;
    },
    updateMinAge: (state, action) => {
      state.minAge = action.payload;
    },
    updateMaxAge: (state, action) => {
      state.maxAge = action.payload;
    },
    updateMinHeight: (state, action) => {
      state.minHeight = action.payload;
    },
    updateMaxHeight: (state, action) => {
      state.maxHeight = action.payload;
    },
    updateLifestyleData: (state, action) => {
      state.lifestyleData = action.payload;
    },
    updateBodyTypeData: (state, action) => {
      state.bodyTypeData = action.payload;
    },
    updateEthnicityData: (state, action) => {
      state.ethnicityData = action.payload;
    },
    updateEyeColorData: (state, action) => {
      state.eyeColorData = action.payload;
    },
    updateHairColorData: (state, action) => {
      state.hairColorData = action.payload;
    },
    updateSmokingData: (state, action) => {
      state.smokingData = action.payload;
    },
    updateDrinkingData: (state, action) => {
      state.drinkingData = action.payload;
    },
    updateRelationshipStatusData: (state, action) => {
      state.relationshipStatusData = action.payload;
    },
    updateEducationData: (state, action) => {
      state.educationData = action.payload;
    },
    updateTor: (state, action) => {
      state.tor = action.payload;
    },

    // Utility Functions
    resetSearchFilterStore: () => {
      return { ...initialState };
    },
    resetSearchFilter: (state) => {
      // General variables
      state.keyword = "";
      state.locationFormattedAddress = "";
      state.locationLatitude = null;
      state.locationLongitude = null;
      state.locationCity = null;
      state.locationState = null;
      state.locationCountry = null;
      state.locationCountryIso = null;
      state.location = "";
      state.location_data = "";
      state.premiumOnly = false;
      state.photos = false;
      state.viewed = false;
      state.unviewed = false;
      state.favorited = false;
      state.favoritedMe = false;
      state.minAge = 18;
      state.maxAge = 60;

      for (let i = 0; i < state.lifestyleData.length; i++) {
        state.lifestyleData[i].isSelected = false;
      }

      for (let i = 0; i < state.bodyTypeData.length; i++) {
        state.bodyTypeData[i].isSelected = false;
      }

      for (let i = 0; i < state.ethnicityData.length; i++) {
        state.ethnicityData[i].isSelected = false;
      }

      for (let i = 0; i < state.eyeColorData.length; i++) {
        state.eyeColorData[i].isSelected = false;
      }

      for (let i = 0; i < state.hairColorData.length; i++) {
        state.hairColorData[i].isSelected = false;
      }

      for (let i = 0; i < state.smokingData.length; i++) {
        state.smokingData[i].isSelected = false;
      }

      for (let i = 0; i < state.drinkingData.length; i++) {
        state.drinkingData[i].isSelected = false;
      }

      for (let i = 0; i < state.relationshipStatusData.length; i++) {
        state.relationshipStatusData[i].isSelected = false;
      }

      for (let i = 0; i < state.educationData.length; i++) {
        state.educationData[i].isSelected = false;
      }

      state.tor = [];

      // Utility variables
      state.lifestyleSelected = [];
      state.bodyTypeSelected = [];
      state.ethnicitySelected = [];
      state.eyeColorSelected = [];
      state.hairColorSelected = [];
      state.smokingSelected = [];
      state.drinkingSelected = [];
      state.relationshipStatusSelected = [];
      state.educationSelected = [];
      state.torSelected = 0;

      state.isFiltered = false;
      state.selectedFilters = [];
      state.triggerSearchFilteredPassthrough = null;
    },
    updateLifestyleSelected: (state, action) => {
      state.lifestyleSelected = action.payload;
    },
    updateBodyTypeSelected: (state, action) => {
      state.bodyTypeSelected = action.payload;
    },
    updateEthnicitySelected: (state, action) => {
      state.ethnicitySelected = action.payload;
    },
    updateEyeColorSelected: (state, action) => {
      state.eyeColorSelected = action.payload;
    },
    updateHairColorSelected: (state, action) => {
      state.hairColorSelected = action.payload;
    },
    updateSmokingSelected: (state, action) => {
      state.smokingSelected = action.payload;
    },
    updateDrinkingSelected: (state, action) => {
      state.drinkingSelected = action.payload;
    },
    updateRelationshipStatusSelected: (state, action) => {
      state.relationshipStatusSelected = action.payload;
    },
    updateEducationSelected: (state, action) => {
      state.educationSelected = action.payload;
    },
    updateTorSelectedCount: (state) => {
      state.torSelected = 0;
    },
    updateIsFiltered: (state) => {
      if (
        (state.keyword !== undefined && state.keyword !== "") ||
        state.location ||
        state.location_data ||
        state.premiumOnly ||
        state.photos ||
        state.viewed ||
        state.unviewed ||
        state.favorited ||
        state.favoritedMe ||
        state.minAge !== 18 ||
        state.maxAge !== 60 ||
        // state.minHeight !== 130 ||
        // state.maxHeight !== 220 ||
        state.lifestyleSelected.length > 0 ||
        state.bodyTypeSelected.length > 0 ||
        state.ethnicitySelected.length > 0 ||
        state.tor.length > 0 ||
        state.eyeColorSelected.length > 0 ||
        state.hairColorSelected.length > 0 ||
        state.smokingSelected.length > 0 ||
        state.drinkingSelected.length > 0 ||
        state.relationshipStatusSelected.length > 0 ||
        state.educationSelected.length > 0
      ) {
        state.isFiltered = true;

        state.triggerSearchFilteredPassthrough = {};
      } else {
        state.isFiltered = false;
      }
    },
    composeSelectedFilters: (state, action) => {
      let selectedFilters = [];

      if (action.payload && action.payload.length > 0) {
        let torListData = [...action.payload];
        let selectedTor = [];

        if (torListData.length > 0) {
          for (let i = 0; i < torListData.length; i++) {
            if (torListData[i].selected === true) {
              selectedTor.push(torListData[i].locale);
            }
          }

          selectedFilters = [...selectedTor];
        }
      }

      if (state.keyword) {
        selectedFilters.push(state.keyword);
      }

      if (state.locationFormattedAddress) {
        selectedFilters.push(state.locationFormattedAddress);
      }

      if (state.premiumOnly) {
        selectedFilters.push("filter.premium");
      }

      if (state.favorited) {
        selectedFilters.push("filter.favorited");
      }

      if (state.favoritedMe) {
        selectedFilters.push("filter.favorited_me");
      }

      if (state.minAge !== 18 || state.maxAge !== 60) {
        selectedFilters.push(`${state.minAge}-${state.maxAge}`);
      }

      // Lifestyle
      for (let i = 0; i < state.lifestyleSelected.length; i++) {
        selectedFilters.push(state.lifestyleSelected[i].display_locale);
      }

      // Body Type
      for (let i = 0; i < state.bodyTypeSelected.length; i++) {
        selectedFilters.push(state.bodyTypeSelected[i].display_locale);
      }

      // Ethnicity
      for (let i = 0; i < state.ethnicitySelected.length; i++) {
        selectedFilters.push(state.ethnicitySelected[i].display_locale);
      }

      // Eye Color
      for (let i = 0; i < state.eyeColorSelected.length; i++) {
        selectedFilters.push(state.eyeColorSelected[i].display_locale);
      }

      // Hair Color
      for (let i = 0; i < state.hairColorSelected.length; i++) {
        selectedFilters.push(state.hairColorSelected[i].display_locale);
      }

      // Smoking
      for (let i = 0; i < state.smokingSelected.length; i++) {
        selectedFilters.push(state.smokingSelected[i].display_locale);
      }

      // Drinking
      for (let i = 0; i < state.drinkingSelected.length; i++) {
        selectedFilters.push(state.drinkingSelected[i].display_locale);
      }

      // Relationship Status
      for (let i = 0; i < state.relationshipStatusSelected.length; i++) {
        selectedFilters.push(
          state.relationshipStatusSelected[i].display_locale
        );
      }

      // Education
      for (let i = 0; i < state.educationSelected.length; i++) {
        selectedFilters.push(state.educationSelected[i].display_locale);
      }

      state.selectedFilters = selectedFilters;
    },
    updateTriggerSearchFilteredPassthrough: (state) => {
      state.triggerSearchFilteredPassthrough = {};
    },
  },
});

export const {
  // General Functions
  updateKeyword,
  updateLocationFormattedAddress,
  updateLocationLatitude,
  updateLocationLongitude,
  updateLocationCity,
  updateLocationState,
  updateLocationCountry,
  updateLocationCountryIso,
  updateLocation,
  updateLocationData,
  updatePremiumOnly,
  updatePhotos,
  updateViewed,
  updateUnviewed,
  updateFavorited,
  updateFavoritedMe,
  updateMinAge,
  updateMaxAge,
  updateMinHeight,
  updateMaxHeight,
  updateLifestyleData,
  updateBodyTypeData,
  updateEthnicityData,
  updateEyeColorData,
  updateHairColorData,
  updateSmokingData,
  updateDrinkingData,
  updateRelationshipStatusData,
  updateEducationData,
  updateTor,

  // Utility Functions
  resetSearchFilterStore,
  resetSearchFilter,
  updateLifestyleSelected,
  updateBodyTypeSelected,
  updateEthnicitySelected,
  updateEyeColorSelected,
  updateHairColorSelected,
  updateSmokingSelected,
  updateDrinkingSelected,
  updateRelationshipStatusSelected,
  updateEducationSelected,
  updateIsFiltered,
  composeSelectedFilters,
  updateTriggerSearchFilteredPassthrough,
} = searchFilterSlice.actions;

export default searchFilterSlice.reducer;
