// General
import "./buy-coins.scss";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Components
import CoinsList from "../../../shared/coins-list/coins-list";
import Banner from "../../../shared/elements/banner/banner";

const BuyCoins = () => {
  // i18next variables
  const { t } = useTranslation();

  return (
    <div id="wallet-buy-coins-subcomponent">
      <div className="padding-container">
        <div className="buy-coins-label">{t("profile.buy_coins")}</div>

        <div className="description">
          <Trans
            i18nKey={t("profile.buy_coins_desc")}
            components={{ br: <br /> }}
          />
        </div>

        <div className="description-2">
          <Trans
            i18nKey={t("profile.buy_coins_desc_2")}
            components={{ br: <br />, b: <b /> }}
          />
        </div>

        <CoinsList />

        <div className="top-gifter-prize-description">
          <Banner type={"coin-shop"} view={"stack"} />
        </div>
      </div>
    </div>
  );
};

export default BuyCoins;
