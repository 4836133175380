// General
import "./photos.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useUploadUserPhotoMutation,
} from "../../../../services/data.service";
// Static Data
import userConst from "../../../../const/userConst";
import utilityConst from "../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateProfilePhoto as updateEditProfilePhoto,
  updatePublicPhotos as updateEditPublicPhotos,
  updatePrivatePhotos as updateEditPrivatePhotos,
} from "../../../../redux/store/editProfileStore";
import {
  updateProfilePreviewPhotoInfo,
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../../redux/store/profileStore";
import {
  updateGeneralLoadingDialog,
  updatePreviewPhotoDialog,
} from "../../../../redux/store/dialogStore";
import { updateWarningToast } from "../../../../redux/store/toastStore";
// browser-image-compression
import imageCompression from "browser-image-compression";
// react-gtm-module
import TagManager from "react-gtm-module";
// @dnd-kit/core
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
// Material UI
import { Divider, Button } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";
import { Grid } from "./grid/grid";
import { SortablePhoto } from "./sortable-photo/sortable-photo";
import { Photo } from "./photo/photo";

const Photos = (props) => {
  const { mode = utilityConst.editProfileMode.mobile } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    uploadUserPhoto,
    {
      data: uploadUserPhotoData,
      error: uploadUserPhotoErrorData,
      isLoading: uploadUserPhotoLoading,
      isSuccess: uploadUserPhotoSuccess,
      isError: uploadUserPhotoError,
    },
  ] = useUploadUserPhotoMutation();

  // General variables
  const [imageData, setImageData] = useState(null);
  const [imageState, setImageState] = useState(null);

  // Redux variables
  const profilePhoto = useSelector((state) => state.editProfile.profilePhoto);
  const publicPhotos = useSelector((state) => state.editProfile.publicPhotos);
  const privatePhotos = useSelector((state) => state.editProfile.privatePhotos);
  const dispatch = useDispatch();

  // DndKit variables
  const [items, setItems] = useState([
    "https://source.unsplash.com/WLUHO9A_xik/900x900",
    "https://source.unsplash.com/R4K8S77qtwI/900x900",
    "https://source.unsplash.com/jJGc21mEh8Q/900x900",
    "https://source.unsplash.com/omNxg6JP6Fo/900x900",
    "https://source.unsplash.com/-M1gkucIqkQ/900x900",
    "https://source.unsplash.com/czOuPVsfHDw/900x900",
  ]);
  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile("?photo=true", true);
  }, []);

  // Lifecycle | Check for update | Get Profile API Response
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      if (getProfileData?.status === 0) {
        dispatch(updateEditProfilePhoto(getProfileData?.data?.profile_photo));
        // dispatch(updateEditPublicPhotos(getProfileData?.data?.public_photos));
        // dispatch(updateEditPrivatePhotos(getProfileData?.data?.private_photos));
      }
    } else if (getProfileError) {
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Lifecycle | Check for update | Upload User Photo API Response
  useEffect(() => {
    if (uploadUserPhotoLoading) {
      dispatch(updateGeneralLoadingDialog(true));
    } else if (uploadUserPhotoSuccess) {
      dispatch(updateGeneralLoadingDialog(false));

      dispatch(
        updateProfileCompletionPercentage(
          uploadUserPhotoData?.data?.profile_progress?.summary?.progress
        )
      );

      dispatch(
        updateEditProfilePhoto(uploadUserPhotoData?.data?.profile_photo)
      );
      dispatch(
        updateEditPublicPhotos(
          uploadUserPhotoData?.data?.public_photos?.filter(
            (photo) =>
              photo?.id !== uploadUserPhotoData?.data?.profile_photo?.id
          )
        )
      );
      // Private Photos
      dispatch(
        updateEditPrivatePhotos(uploadUserPhotoData?.data?.private_photos)
      );

      setImageData(null);
      setImageState(null);

      getProfile("?photo=true", false);
    } else if (uploadUserPhotoError) {
      switch (uploadUserPhotoErrorData?.data?.status) {
        case -1:
          dispatch(updateGeneralLoadingDialog(false));

          const warningToastObj = {
            message: uploadUserPhotoErrorData?.data?.message || "",
            autoClose: 3000,
          };
          dispatch(updateWarningToast(warningToastObj));
          break;
        default:
          break;
      }
    }
  }, [uploadUserPhotoLoading, uploadUserPhotoSuccess, uploadUserPhotoError]);

  // Lifecycle | Check for update | imageData & orientationData
  useEffect(() => {
    if (!imageData || !imageState) return;

    const obj = {
      photos: [
        {
          is_private: imageState === "private" ? true : false,
          base64: imageData.image,
        },
      ],
    };

    uploadUserPhoto(obj);
  }, [imageData, imageState]);

  // Event Handlers | Button
  const onFilePicker = (state) => async (event) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-25-25.4-Button",
      },
    });

    setImageState(state);

    const file = event.target.files[0];

    const options = {
      maxSizeMB: 1,
    };

    if (file !== undefined) {
      try {
        const compressedFile = await imageCompression(file, options);
        const imageData = await readFile(compressedFile);
        setImageData((prevState) => ({
          ...prevState,
          image: imageData,
          file: file,
        }));
      } catch (error) {
        console.log(error);
      }
    }
  };
  const onPreviewPhoto = (photo) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-25-25.5-Button",
      },
    });

    const obj = {
      id: photo?.id,
      photoUrl: photo?.original_photo,
      isPrivate: photo?.is_private,
      isProfilePhoto: profilePhoto?.id === photo?.id,
      isApproved:
        photo?.photo_status === userConst.photoApprovalStatus.approved,
      deniedReason: photo?.denied_reason,
    };

    dispatch(updateProfilePreviewPhotoInfo(obj));
    dispatch(updatePreviewPhotoDialog(true));
  };

  // DndKit Functions
  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  };
  const handleDragCancel = () => {
    setActiveId(null);
  };

  // Utility Functions
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  const returnPhotoStatus = (photoStatus) => {
    let status = "";

    switch (photoStatus) {
      case userConst.photoApprovalStatus.approved:
        // status = t("profile.approve");
        break;
      case userConst.photoApprovalStatus.inReview:
        status = t("profile.in_review");
        break;
      case userConst.photoApprovalStatus.rejected:
        status = t("profile.rejected");
        break;
      default:
        break;
    }

    return status;
  };
  const returnPublicPhotoLength = () => {
    return profilePhoto ? publicPhotos?.length + 1 : publicPhotos?.length;
  };

  if (mode === utilityConst.editProfileMode.mobile) {
    return (
      <div id="mobile-profile-edit-photos-subcomponent">
        <div className="edit-photos-padding-container">
          <div className="public-photos-container">
            <div className="public-photos-label">
              {t("profile.public_photos")}
            </div>

            {/* <div className="dnd-container">
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
              >
                <SortableContext items={items} strategy={rectSortingStrategy}>
                  <Grid columns={3}>
                    {items?.map((url, index) => (
                      <SortablePhoto key={url} url={url} index={index} />
                    ))}
                  </Grid>
                </SortableContext>

                <DragOverlay adjustScale={true}>
                  {activeId ? (
                    <Photo url={activeId} index={items.indexOf(activeId)} />
                  ) : null}
                </DragOverlay>
              </DndContext>
            </div> */}

            {
              <div className="public-photo-stack-container">
                <div className="top-stack">
                  <div className="profile-photo-container">
                    <div className="profile-photo">
                      {profilePhoto ? (
                        <img
                          className="profile-photo-img"
                          src={profilePhoto?.original_photo}
                          onClick={() => onPreviewPhoto(profilePhoto)}
                        />
                      ) : (
                        <Button
                          className="upload-photo-container"
                          disableRipple
                          component="label"
                        >
                          <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={onFilePicker("public")}
                          />
                          <AddRoundedIcon className="add-photo-icon" />
                        </Button>
                      )}

                      <div
                        className={`photo-status-container ${
                          profilePhoto?.photo_status === "IN REVIEW"
                            ? "in-review-background"
                            : profilePhoto?.photo_status === "REJECTED"
                            ? "red-background"
                            : ""
                        }`}
                      >
                        {returnPhotoStatus(profilePhoto?.photo_status)}
                      </div>
                    </div>
                  </div>

                  <div className="side-image-container">
                    <div className="side-image">
                      {publicPhotos[0]?.original_photo ? (
                        <img
                          className="public-photo-img"
                          src={publicPhotos[0]?.original_photo}
                          onClick={() => onPreviewPhoto(publicPhotos[0])}
                        />
                      ) : (
                        <Button
                          className="upload-photo-container"
                          disableRipple
                          component="label"
                        >
                          <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={onFilePicker("public")}
                          />
                          <AddRoundedIcon className="add-photo-icon" />
                        </Button>
                      )}

                      <div
                        className={`photo-status-container ${
                          publicPhotos[0]?.photo_status === "IN REVIEW"
                            ? "in-review-background"
                            : publicPhotos[0]?.photo_status === "REJECTED"
                            ? "red-background"
                            : ""
                        }`}
                      >
                        {returnPhotoStatus(publicPhotos[0]?.photo_status)}
                      </div>
                    </div>

                    <div className="side-image">
                      {publicPhotos[1]?.original_photo ? (
                        <img
                          className="public-photo-img"
                          src={publicPhotos[1]?.original_photo}
                          onClick={() => onPreviewPhoto(publicPhotos[1])}
                        />
                      ) : (
                        <Button
                          className="upload-photo-container"
                          disableRipple
                          component="label"
                        >
                          <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={onFilePicker("public")}
                          />
                          <AddRoundedIcon className="add-photo-icon" />
                        </Button>
                      )}

                      <div
                        className={`photo-status-container ${
                          publicPhotos[1]?.photo_status === "IN REVIEW"
                            ? "in-review-background"
                            : publicPhotos[1]?.photo_status === "REJECTED"
                            ? "red-background"
                            : ""
                        }`}
                      >
                        {returnPhotoStatus(publicPhotos[1]?.photo_status)}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bottom-stack">
                  <div className="bottom-left-image-container">
                    <div className="bottom-image">
                      {publicPhotos[4]?.original_photo ? (
                        <img
                          className="public-photo-img"
                          src={publicPhotos[4]?.original_photo}
                          onClick={() => onPreviewPhoto(publicPhotos[4])}
                        />
                      ) : (
                        <Button
                          className="upload-photo-container"
                          disableRipple
                          component="label"
                        >
                          <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={onFilePicker("public")}
                          />
                          <AddRoundedIcon className="add-photo-icon" />
                        </Button>
                      )}

                      <div
                        className={`photo-status-container ${
                          publicPhotos[4]?.photo_status === "IN REVIEW"
                            ? "in-review-background"
                            : publicPhotos[4]?.photo_status === "REJECTED"
                            ? "red-background"
                            : ""
                        }`}
                      >
                        {returnPhotoStatus(publicPhotos[4]?.photo_status)}
                      </div>
                    </div>
                    <div className="bottom-image">
                      {publicPhotos[3]?.original_photo ? (
                        <img
                          className="public-photo-img"
                          src={publicPhotos[3]?.original_photo}
                          onClick={() => onPreviewPhoto(publicPhotos[3])}
                        />
                      ) : (
                        <Button
                          className="upload-photo-container"
                          disableRipple
                          component="label"
                        >
                          <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={onFilePicker("public")}
                          />
                          <AddRoundedIcon className="add-photo-icon" />
                        </Button>
                      )}

                      <div
                        className={`photo-status-container ${
                          publicPhotos[3]?.photo_status === "IN REVIEW"
                            ? "in-review-background"
                            : publicPhotos[3]?.photo_status === "REJECTED"
                            ? "red-background"
                            : ""
                        }`}
                      >
                        {returnPhotoStatus(publicPhotos[3]?.photo_status)}
                      </div>
                    </div>
                  </div>

                  <div className="bottom-right-image-container">
                    <div className="bottom-image">
                      {publicPhotos[2]?.original_photo ? (
                        <img
                          className="public-photo-img"
                          src={publicPhotos[2]?.original_photo}
                          onClick={() => onPreviewPhoto(publicPhotos[2])}
                        />
                      ) : (
                        <Button
                          className="upload-photo-container"
                          disableRipple
                          component="label"
                        >
                          <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={onFilePicker("public")}
                          />
                          <AddRoundedIcon className="add-photo-icon" />
                        </Button>
                      )}

                      <div
                        className={`photo-status-container ${
                          publicPhotos[2]?.photo_status === "IN REVIEW"
                            ? "in-review-background"
                            : publicPhotos[2]?.photo_status === "REJECTED"
                            ? "red-background"
                            : ""
                        }`}
                      >
                        {returnPhotoStatus(publicPhotos[2]?.photo_status)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

            <div className="public-photo-note">
              <InfoRoundedIcon className="info-icon" />
              <Trans
                i18nKey={"profile.public_photo_footer"}
                components={{ br: <br /> }}
              />
            </div>
          </div>

          <div className="private-photos-container">
            <div className="private-photos-label">
              {t("profile.private_photos")}

              <LockRoundedIcon className="lock-icon" />
            </div>

            <div className="private-photo-body-container">
              {Array.from({ length: 3 }, (_, index) => {
                if (privatePhotos[index]) {
                  return (
                    <div
                      className="private-photo"
                      onClick={() => onPreviewPhoto(privatePhotos[index])}
                      key={index}
                    >
                      <img
                        className="private-photo-image"
                        src={privatePhotos[index]?.original_photo}
                      />

                      <div
                        className={`photo-status-container ${
                          privatePhotos[index]?.photo_status === "IN REVIEW"
                            ? "in-review-background"
                            : privatePhotos[index]?.photo_status === "REJECTED"
                            ? "red-background"
                            : ""
                        }`}
                      >
                        {returnPhotoStatus(privatePhotos[index]?.photo_status)}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="private-photo">
                      <Button
                        className="upload-photo-container"
                        disableRipple
                        component="label"
                      >
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={onFilePicker("private")}
                        />
                        <AddRoundedIcon className="add-photo-icon" />
                      </Button>
                    </div>
                  );
                }
              })}

              {privatePhotos?.length >= 3 &&
                Array.from({ length: 3 }, (_, index) => {
                  if (privatePhotos[index + 3]) {
                    return (
                      <div
                        className="private-photo"
                        onClick={() => onPreviewPhoto(privatePhotos[index + 3])}
                        key={index + 3}
                      >
                        <img
                          className="private-photo-image"
                          src={privatePhotos[index + 3]?.original_photo}
                        />

                        <div
                          className={`photo-status-container ${
                            privatePhotos[index + 3]?.photo_status ===
                            "IN REVIEW"
                              ? "in-review-background"
                              : privatePhotos[index + 3]?.photo_status ===
                                "REJECTED"
                              ? "red-background"
                              : ""
                          }`}
                        >
                          {returnPhotoStatus(
                            privatePhotos[index + 3]?.photo_status
                          )}
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className="private-photo">
                        <Button
                          className="upload-photo-container"
                          disableRipple
                          component="label"
                        >
                          <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={onFilePicker("private")}
                          />
                          <AddRoundedIcon className="add-photo-icon" />
                        </Button>
                      </div>
                    );
                  }
                })}

              {false &&
                privatePhotos?.map((privatePhoto, index) => (
                  <div className="private-photo" key={index}>
                    <img
                      className="private-photo-image"
                      src={privatePhoto?.original_photo}
                    />

                    <div
                      className={`photo-status-container ${
                        privatePhoto?.photo_status === "IN REVIEW"
                          ? "in-review-background"
                          : profilePhoto?.photo_status === "REJECTED"
                          ? "red-background"
                          : ""
                      }`}
                    >
                      {returnPhotoStatus(privatePhoto?.photo_status)}
                    </div>
                  </div>
                ))}

              {false && privatePhotos?.length < 6 && (
                <div className="private-photo">
                  <Button
                    className="upload-photo-container"
                    disableRipple
                    component="label"
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={onFilePicker("private")}
                    />
                    <AddRoundedIcon className="add-photo-icon" />
                  </Button>
                </div>
              )}
            </div>

            <div className="private-photo-note">
              <Trans
                i18nKey={"profile.private_photo_footer"}
                components={{ br: <br /> }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else if (mode === utilityConst.editProfileMode.desktop) {
    return (
      <div id="desktop-profile-edit-photos-subcomponent">
        <div className="padding-container">
          <div className="public-photos-container">
            <div className="public-photos-top-container">
              <div className="public-photos-label-container">
                <div className="public-photos-label">
                  {t("profile.public_photos")}
                </div>

                <div className="public-photos-amount">
                  ({returnPublicPhotoLength()} {t("profile.edit_photos")})
                </div>
              </div>

              {returnPublicPhotoLength() < 6 && (
                <Button
                  className="add-photos-button-container"
                  disableRipple
                  component="label"
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={onFilePicker("public")}
                  />

                  <div className="add-photo-icon-container">
                    {getIcon("addRoundedIcon", "add-photo-icon")}
                  </div>
                  <div className="add-photo-label">
                    {t("profile.add_photos")}
                  </div>
                </Button>
              )}
            </div>

            <Divider className="short-divider" />

            {profilePhoto || publicPhotos?.length > 0 ? (
              <div className="photos-container">
                {profilePhoto && (
                  <div
                    className="photo-container"
                    onClick={() => onPreviewPhoto(profilePhoto)}
                  >
                    <img
                      className="public-photo"
                      src={profilePhoto?.original_photo}
                    />

                    {profilePhoto?.photo_status ===
                      userConst.photoApprovalStatus.inReview && (
                      <div className="in-review-overlay">
                        {t("profile.in_review")}
                      </div>
                    )}

                    {profilePhoto?.photo_status ===
                      userConst.photoApprovalStatus.rejected && (
                      <div className="rejected-overlay">
                        <div className="rejected-label">
                          {t("profile.rejected")}
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {publicPhotos?.map((photo, index) => {
                  return (
                    <div
                      className="photo-container"
                      onClick={() => onPreviewPhoto(photo)}
                      key={index}
                    >
                      <img
                        className="public-photo"
                        src={photo?.original_photo}
                      />

                      {photo?.photo_status ===
                        userConst.photoApprovalStatus.inReview && (
                        <div className="in-review-overlay">
                          {t("profile.in_review")}
                        </div>
                      )}

                      {photo?.photo_status ===
                        userConst.photoApprovalStatus.rejected && (
                        <div className="rejected-overlay">
                          <div className="rejected-label">
                            {t("profile.rejected")}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>

          <div className="private-photos-container">
            <div className="private-photos-top-container">
              <div className="private-photos-label-container">
                <div className="private-photos-label">
                  {t("profile.private_photos")}
                </div>

                <div className="private-photos-amount">
                  ({privatePhotos?.length} {t("profile.edit_photos")})
                </div>
              </div>

              {privatePhotos?.length < 6 && (
                <Button
                  className="add-photos-button-container"
                  disableRipple
                  component="label"
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={onFilePicker("private")}
                  />

                  <div className="add-photo-icon-container">
                    {getIcon("addRoundedIcon", "add-photo-icon")}
                  </div>
                  <div className="add-photo-label">
                    {t("profile.add_photos")}
                  </div>
                </Button>
              )}
            </div>

            <Divider className="short-divider" />

            {privatePhotos?.length > 0 ? (
              <div className="photos-container">
                {privatePhotos?.map((photo, index) => (
                  <div
                    className="photo-container"
                    onClick={() => onPreviewPhoto(photo)}
                    key={index}
                  >
                    <img
                      className="private-photo"
                      src={photo?.original_photo}
                    />

                    {photo?.photo_status ===
                      userConst.photoApprovalStatus.inReview && (
                      <div className="in-review-overlay">
                        {t("profile.in_review")}
                      </div>
                    )}

                    {photo?.photo_status ===
                      userConst.photoApprovalStatus.rejected && (
                      <div className="rejected-overlay">
                        <div className="rejected-label">
                          {t("profile.rejected")}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
};

export default Photos;
