// General
import "./navbar-coin.scss";
import { useEffect } from "react";
// Services
import { useLazyGetVirtualCurrencyCoinBalanceQuery } from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateCoinExpiryInterval,
  updateCoinExpirySeconds,
  startCoinExpiryTimer,
} from "../../../../../redux/store/navbarStore";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";

const NavbarCoin = (props) => {
  const { disableButton = false } = props;

  // API variables
  const [
    getVirtualCurrencyCoinBalance,
    {
      data: getVirtualCurrencyCoinBalanceData,
      error: getVirtualCurrencyCoinBalanceErrorData,
      isFetching: getVirtualCurrencyCoinBalanceFetching,
      isLoading: getVirtualCurrencyCoinBalanceLoading,
      isSuccess: getVirtualCurrencyCoinBalanceSuccess,
      isError: getVirtualCurrencyCoinBalanceError,
    },
  ] = useLazyGetVirtualCurrencyCoinBalanceQuery();

  // Redux variables
  const coinExpiryInterval = useSelector(
    (state) => state.navbar.coinExpiryInterval
  );
  const coinExpirySeconds = useSelector(
    (state) => state.navbar.coinExpirySeconds
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getVirtualCurrencyCoinBalance(null, true);
  }, []);

  // Lifecycle | Check for update | getVirtualCurrencyCoinBalance API Response
  useEffect(() => {
    if (
      getVirtualCurrencyCoinBalanceFetching ||
      getVirtualCurrencyCoinBalanceLoading
    ) {
    } else if (getVirtualCurrencyCoinBalanceSuccess) {
      if (getVirtualCurrencyCoinBalanceData?.data?.coins_expiry) {
        computeExpiryDate(
          getVirtualCurrencyCoinBalanceData?.data?.coins_expiry
            ?.expiry_date_epoch,
          getVirtualCurrencyCoinBalanceData?.data?.coins_expiry?.expiry_date
        );
      }
    } else if (getVirtualCurrencyCoinBalanceError) {
    }
  }, [
    getVirtualCurrencyCoinBalanceFetching,
    getVirtualCurrencyCoinBalanceLoading,
    getVirtualCurrencyCoinBalanceSuccess,
    getVirtualCurrencyCoinBalanceError,
  ]);

  // Event Handlers | Button
  const onNavigateToCoin = () => {
    if (disableButton) return;

    onNavigate(routeConst.profile.wallet.path);
  };

  // Utility Functions
  const computeExpiryDate = (epoch, date) => {
    if (coinExpiryInterval) return;

    const currentDate = moment();
    const expiryDate = moment(date);
    const expiryDateEpoch = epoch;
    const diffInSeconds = expiryDate.diff(currentDate, "seconds");

    dispatch(updateCoinExpirySeconds(diffInSeconds));
    dispatch(
      updateCoinExpiryInterval(
        setInterval(() => {
          dispatch(startCoinExpiryTimer());
        }, 1000)
      )
    );
  };
  const getExpiringCopyWriting = (seconds) => {
    if (seconds > 24 * 60 * 60) {
      return `${Math.floor(seconds / (60 * 60 * 24))} ${t("common.days")}`;
    } else {
      const days = Math.floor(seconds / (60 * 60 * 24));
      const hours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
      const minutes = Math.floor((seconds % (60 * 60)) / 60);
      const remainingSeconds = seconds % 60;

      return `${days}:${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
    }
  };

  return (
    <div id="navbar-coin-subcomponent" onClick={onNavigateToCoin}>
      {getIcon(
        "sbCoinIcon",
        getVirtualCurrencyCoinBalanceData?.data?.coins_expiry
          ? "sb-coin-small-icon"
          : "sb-coin-icon"
      )}

      {getVirtualCurrencyCoinBalanceData?.data?.coins_expiry && (
        <div className="coins-expiring-countdown">
          <Trans
            i18nKey={"common.coins_expiring_in"}
            components={{
              br: <br />,
              span: <span className="red-expiring-label" />,
            }}
            values={{ countdown: getExpiringCopyWriting(coinExpirySeconds) }}
          />
        </div>
      )}
    </div>
  );
};

export default NavbarCoin;
