// General
import "./edit-tor.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetTorListQuery,
  useSaveTorListMutation,
  useLazyGetProfileCompletionQuery,
} from "../../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateTor,
  updateIsUnsavedChanges,
} from "../../../../../redux/store/editProfileStore";
import {
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../../../redux/store/profileStore";
import { updateUpdateProfileFullscreenDialog } from "../../../../../redux/store/dialogStore";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Components
import Spinner from "../../../elements/spinner/spinner";
import TorTag from "../../../elements/tor-tag/tor-tag";

const EditTor = (props) => {
  const { addPaddingTop = false } = props;

  // API variables
  const [
    getTorList,
    {
      data: getTorListData,
      error: getTorListErrorData,
      isFetching: getTorListFetching,
      isLoading: getTorListLoading,
      isSuccess: getTorListSuccess,
      isError: getTorListError,
    },
  ] = useLazyGetTorListQuery();
  const [
    saveTorList,
    {
      data: saveTorListData,
      error: saveTorListErrorData,
      isLoading: saveTorListLoading,
      isSuccess: saveTorListSuccess,
      isError: saveTorListError,
    },
  ] = useSaveTorListMutation();
  const [
    getProfileCompletion,
    {
      data: getProfileCompletionData,
      error: getProfileCompletionErrorData,
      isFetching: getProfileCompletionFetching,
      isLoading: getProfileCompletionLoading,
      isSuccess: getProfileCompletionSuccess,
      isError: getProfileCompletionError,
    },
  ] = useLazyGetProfileCompletionQuery();

  // General variables
  const [localTor, setLocalTor] = useState(null);
  const [description, setDescription] = useState(null);

  // Redux variables
  const tor = useSelector((state) => state.editProfile.tor);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    setLocalTor(tor || []);

    getTorList(null, true);
  }, []);

  // Lifecycle | Check for update | saveTorList API Response
  useEffect(() => {
    if (saveTorListLoading) {
    } else if (saveTorListSuccess) {
      switch (saveTorListData?.status) {
        case 0:
          dispatch(updateTor(saveTorListData?.data?.results));
          dispatch(updateIsUnsavedChanges(false));

          getProfileCompletion(null, false);
          break;
        default:
          break;
      }
    } else if (saveTorListError) {
    }
  }, [saveTorListLoading, saveTorListSuccess, saveTorListError]);

  // Lifecycle | Check for update | getProfileCompletion API Response
  useEffect(() => {
    if (getProfileCompletionFetching || getProfileCompletionLoading) {
    } else if (getProfileCompletionSuccess) {
      dispatch(
        updateProfileCompletionPercentage(
          getProfileCompletionData?.data?.profile_progress?.summary?.progress
        )
      );
      dispatch(
        updateProfileRequirementPopup(
          getProfileCompletionData?.data?.profile_progress?.summary?.requirement
        )
      );

      dispatch(updateUpdateProfileFullscreenDialog(false));
    } else if (getProfileCompletionError) {
    }
  }, [
    getProfileCompletionFetching,
    getProfileCompletionLoading,
    getProfileCompletionSuccess,
    getProfileCompletionError,
  ]);

  // Lifecycle | Check for update | localTor
  useEffect(() => {
    if (
      tor?.length !== localTor?.length ||
      tor?.some((item) => !localTor?.some((tor) => tor?.id === item?.id))
    ) {
      dispatch(updateIsUnsavedChanges(true));
    } else {
      dispatch(updateIsUnsavedChanges(false));
    }
  }, [localTor]);

  // Event Handlers | Button
  const onSelectTor = (tor) => {
    let torTemp = [...localTor];

    if (!torTemp?.some((item) => item?.id === tor?.id)) {
      if (torTemp?.length < returnMaxTor()) {
        torTemp?.push(tor);
      } else if (returnMaxTor() === 1) {
        torTemp = [tor];
      }
    } else {
      torTemp = torTemp?.filter((item) => item?.id !== tor?.id);
    }

    setDescription(tor?.description);
    setLocalTor(torTemp);
  };
  const onSaveTor = () => {
    if (!isFormValid()) return;

    const obj = {
      tags: localTor?.map((item) => item?.id),
    };
    saveTorList(obj);
  };

  // Utility Functions
  const isFormValid = () => {
    return (
      localTor?.length >= returnMinTor() &&
      localTor?.length <= returnMaxTor() &&
      !saveTorListLoading &&
      !getProfileCompletionFetching
    );
  };
  const getTorListsData = () => {
    return getTorListData?.data?.results;
  };
  const checkTor = (tor) => {
    return localTor?.find((item) => item?.id === tor?.id);
  };
  const returnMinTor = () => {
    return 1;
  };
  const returnMaxTor = () => {
    return 5;
  };

  return (
    <div id="tor-subcomponent">
      <div className="padding-container">
        <div className="top-container">
          <div
            className={`tor-title ${
              addPaddingTop ? "add-edit-padding-top" : ""
            }`}
          >
            <Trans
              i18nKey={"profile.what_are_your_tor"}
              components={{ br: <br />, sup: <sup className="trade-mark" /> }}
            />
          </div>

          <div className="tor-description">
            {t("profile.edit_tor_description")}
          </div>

          <div className="tor-choose-options">
            {t("common.chosen_n_out_of_max_options", {
              n: localTor?.length,
              max: returnMaxTor(),
            })}
          </div>

          {getTorListFetching || getTorListLoading ? (
            <Spinner />
          ) : (
            <div className="tor-body-container">
              {getTorListsData()?.map((tor, index) => (
                <div onClick={() => onSelectTor(tor)} key={index}>
                  <TorTag
                    torName={tor?.locale}
                    description={tor?.description}
                    blur={false}
                    match={checkTor(tor) ? true : false}
                    interactable={false}
                    readOnly={true}
                    showToolTip={false}
                    theme={tor?.theme}
                    unselectedBackgroundColor="white"
                    selectedBackgroundColor="#f8ecec"
                    unselectedTextColor="#A2A2A2"
                    selectedTextColor="#710D0D"
                    unselectedBorderColor="#d8d8d8"
                    selectedBorderColor="#d8d8d8"
                    borderWidth="1px"
                    fontWeight="600"
                    fontSize="1em"
                    key={index}
                  />
                </div>
              ))}
            </div>
          )}

          {description && (
            <div
              className="tor-tag-description"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          )}
        </div>
      </div>

      <div className="bottom-container">
        <div
          className={`save-button ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onSaveTor}
        >
          {saveTorListLoading || getProfileCompletionFetching ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("profile.tor_selected", {
              n: localTor?.length,
              max: returnMaxTor(),
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default EditTor;
