// General
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// Services
import { sessionService } from "../../../../services/session.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // General Functions
  updateIsLoggedIn,
  updateApiToken,
  updateSpiToken,
  // Utility Functions
} from "../../../../redux/store/publicStore";
import { updateEmail } from "../../../../redux/store/userStore";
// Custom Hooks
import useCustomNavigate from "../../custom-hooks/useCustomNavigate-hook";

const CheckLoginMounted = () => {
  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const dispatch = useDispatch();

  // Router variables
  const location = useLocation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    // Check if user is logged in
    if (!sessionService.getApiToken() || !sessionService.getSpiToken()) {
      dispatch(updateIsLoggedIn(false));

      if (location.pathname.includes("redirect_handler") === false) {
        if (location.pathname.includes("join")) {
          onNavigate(routeConst.join.path);
        } else {
          onNavigate(routeConst.landing.path);
        }
      }
    } else {
      dispatch(updateApiToken(sessionService.getApiToken()));
      dispatch(updateSpiToken(sessionService.getSpiToken()));
      dispatch(updateEmail(sessionService.getEmail()));
      dispatch(updateIsLoggedIn(true));
    }
  }, []);

  // Lifecycle | Check for update | location
  useEffect(() => {
    if (isLoggedIn) {
      if (
        location.pathname === routeConst.landing.path ||
        location.pathname === routeConst.login.path ||
        location.pathname === routeConst.join.path ||
        location.pathname === routeConst.accountSignup.path
      ) {
        onNavigate(routeConst.search.path);
      }
    }
  }, [isLoggedIn, location]);

  return <div id="check-login-mounted-shadow-component"></div>;
};

export default CheckLoginMounted;
