// General
import "./lgbtq-description-dialog.scss";
import { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateLgbtqDescriptionDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const LgbtqDescriptionDialog = () => {
  // Redux variables
  const lgbtqDescriptionDialog = useSelector(
    (state) => state.dialog.lgbtqDescriptionDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!lgbtqDescriptionDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D40",
      },
    });
  }, [lgbtqDescriptionDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateLgbtqDescriptionDialog(false));
  };

  return (
    <Dialog
      className="custom-radius25-dialog"
      open={lgbtqDescriptionDialog}
      onClose={onCloseDialog}
    >
      <div id="lgbtq-description-dialog">
        <div className="dialog-header">
          <div className="close-button-container">
            <CloseIcon className="close-button" onClick={onCloseDialog} />
          </div>
        </div>

        <div className="padding-container">
          <div className="lgbtq-title-container">
            <div className="lgbtq-badge-container">
              {getIcon("lgbtqIcon", "lgbtq-badge")}
            </div>
            <div className="lgbtq-title">{t("profile.lgbtq_dialog_title")}</div>
          </div>

          <div className="lgbtq-description">
            {t("profile.lgbtq_dialog_description")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default LgbtqDescriptionDialog;
