// General
import "./tor-tag.scss";
// Static Data
import routeConst from "../../../../const/routeConst";
import profileConst from "../../../../const/profileConst";
// Redux
import { useDispatch } from "react-redux";
import { updatePaymentTag } from "../../../../redux/store/paymentStore";
import { updateViewTorDescriptionDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Tooltip, useMediaQuery } from "@mui/material";
import { updateTorDescription } from "../../../../redux/store/profileStore";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";
// Components
import DefaultTag from "./default-tag/default-tag";
import CNYTag from "./cny-tag/cny-tag";

const TorTag = (props) => {
  const {
    torName,
    description,
    blur,
    match = true,
    interactable = true,
    readOnly = false,
    showToolTip = true,
    unselectedBackgroundColor = "white",
    selectedBackgroundColor = "#f4ecec",
    unselectedTextColor = "",
    selectedTextColor = "",
    unselectedBorderColor = "",
    selectedBorderColor = "",
    borderWidth = "",
    fontSize = "",
    fontWeight = "",

    theme = profileConst.torTheme.default,
  } = props;

  // Redux variables
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Events Handlers | Button
  const onInteractTor = () => {
    if (readOnly) return;

    if (blur || !interactable) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-S8-8.1-Button",
        },
      });

      dispatch(updatePaymentTag("pwa-view-tor-description-sd-blocker"));
      onNavigate(routeConst.upgrade.path);
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-S8-8.2-Button",
        },
      });

      // Open Dialog for mobile
      dispatch(updateTorDescription(description));
      dispatch(updateViewTorDescriptionDialog(true));
    }
  };

  if (isMobile || showToolTip === false) {
    switch (theme) {
      case profileConst.torTheme.cny:
        return (
          <div onClick={onInteractTor}>
            <CNYTag
              torName={torName}
              blur={blur}
              match={match}
              unselectedBackgroundColor={unselectedBackgroundColor}
              selectedBackgroundColor={selectedBackgroundColor}
              unselectedTextColor={unselectedTextColor}
              selectedTextColor={selectedTextColor}
              unselectedBorderColor={unselectedBorderColor}
              selectedBorderColor={selectedBorderColor}
              borderWidth={borderWidth}
              fontSize={fontSize}
              fontWeight={fontWeight}
            />
          </div>
        );
      case profileConst.torTheme.default:
      default:
        return (
          <div onClick={onInteractTor}>
            <DefaultTag
              torName={torName}
              blur={blur}
              match={match}
              unselectedBackgroundColor={unselectedBackgroundColor}
              selectedBackgroundColor={selectedBackgroundColor}
              unselectedTextColor={unselectedTextColor}
              selectedTextColor={selectedTextColor}
              unselectedBorderColor={unselectedBorderColor}
              selectedBorderColor={selectedBorderColor}
              borderWidth={borderWidth}
              fontSize={fontSize}
              fontWeight={fontWeight}
            />
          </div>
        );
    }
  } else {
    switch (theme) {
      case profileConst.torTheme.cny:
        return (
          <Tooltip
            id="custom-sugarbook-tooltip"
            title={
              <div
                className="tor-tooltip"
                dangerouslySetInnerHTML={
                  blur
                    ? { __html: t("invite_friends.upgrade_to_premium") }
                    : { __html: description }
                }
              ></div>
            }
            followCursor
          >
            <div onClick={onInteractTor}>
              <CNYTag
                torName={torName}
                blur={blur}
                match={match}
                unselectedBackgroundColor={unselectedBackgroundColor}
                selectedBackgroundColor={selectedBackgroundColor}
                unselectedTextColor={unselectedTextColor}
                selectedTextColor={selectedTextColor}
                unselectedBorderColor={unselectedBorderColor}
                selectedBorderColor={selectedBorderColor}
              />
            </div>
          </Tooltip>
        );
      case profileConst.torTheme.default:
      default:
        return (
          <Tooltip
            id="custom-sugarbook-tooltip"
            title={
              <div
                className="tor-tooltip"
                dangerouslySetInnerHTML={
                  blur
                    ? { __html: t("invite_friends.upgrade_to_premium") }
                    : { __html: description }
                }
              ></div>
            }
            followCursor
          >
            <div onClick={onInteractTor}>
              <DefaultTag
                torName={torName}
                blur={blur}
                match={match}
                unselectedBackgroundColor={unselectedBackgroundColor}
                selectedBackgroundColor={selectedBackgroundColor}
                unselectedTextColor={unselectedTextColor}
                selectedTextColor={selectedTextColor}
                unselectedBorderColor={unselectedBorderColor}
                selectedBorderColor={selectedBorderColor}
                borderWidth={borderWidth}
                fontSize={fontSize}
                fontWeight={fontWeight}
              />
            </div>
          </Tooltip>
        );
    }
  }
};

export default TorTag;
