// General
import "./cny-tag.scss";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";

const CNYTag = (props) => {
  const {
    torName,
    blur,
    match,
    unselectedBackgroundColor = "white",
    selectedBackgroundColor = "#f4ecec",
    unselectedTextColor = "white",
    selectedTextColor = "#710d0d",
    unselectedBorderColor = "rgba(113, 13, 13, .2)",
    selectedBorderColor = "rgba(113, 13, 13, .2)",
    borderWidth = "",
    fontSize = "",
    fontWeight = "",
  } = props;

  // Custom Hooks Functions
  const getIcon = IconManager();

  return (
    <div
      id="tor-tag-cny-tag-subcomponent"
      className={`${blur ? "blur" : ""} ${
        match ? "cny-matched" : "cny-unmatched"
      }
      "cny-tor-theme"`}
      style={{
        borderWidth: borderWidth,
        borderColor: match ? selectedBorderColor : unselectedBorderColor,
        backgroundColor: match ? selectedBackgroundColor : "",
      }}
    >
      <div className="left-tor-asset-container">
        {getIcon("torCnyIcon", "tor-asset")}
      </div>

      <div
        className="tor-name"
        style={{
          color: match ? selectedTextColor : unselectedTextColor,
          fontWeight: fontWeight,
          fontSize: fontSize,
        }}
      >
        {torName}
      </div>

      <div className="right-tor-asset-container">
        {getIcon("torCnyIcon", "tor-asset")}
      </div>
    </div>
  );
};

export default CNYTag;
