// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // General variables
  coinExpiryInterval: null,
  coinExpirySeconds: null,
};

export const navbarSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    // General Functions
    updateCoinExpiryInterval: (state, action) => {
      if (state.coinExpiryInterval) return;

      state.coinExpiryInterval = action.payload;
    },
    updateCoinExpirySeconds: (state, action) => {
      state.coinExpirySeconds = action.payload;
    },
    startCoinExpiryTimer: (state) => {
      state.coinExpirySeconds--;

      if (state.coinExpirySeconds <= 0) {
        clearInterval(state.coinExpiryInterval);
        state.coinExpiryInterval = null;
      }
    },
    clearCoinExpiryTimer: (state) => {
      clearInterval(state.coinExpiryInterval);
      state.coinExpiryInterval = null;
      state.coinExpirySeconds = null;
    },

    // Utility Functions
    resetNavbarStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  // General Functions
  updateCoinExpiryInterval,
  updateCoinExpirySeconds,
  startCoinExpiryTimer,
  clearCoinExpiryTimer,

  // Utility Functions
  resetNavbarStore,
} = navbarSlice.actions;

export default navbarSlice.reducer;
