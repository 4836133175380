// General
import "./sugarbook-pin-element.scss";
import { useEffect } from "react";
// Services
import { useLazyGetUserAioQuery } from "../../../../../services/data.service";

const SugarbookPinElement = () => {
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // Lifecycle | Mounted
  useEffect(() => {
    getUserAio(null, true);
  }, []);

  if (
    getUserAioSuccess &&
    getUserAioData?.data?.live_streaming?.notices?.length > 0
  ) {
    return (
      <div id="sugarbook-pin-element-subcomponent">
        {getUserAioData?.data?.live_streaming?.notices?.map((notice, index) => {
          if (notice?.type === "in_chat")
            return (
              <div
                className="sugarbook-pin-element-container"
                style={{
                  background: `#${notice?.metadata?.background_color}`,
                  opacity: notice?.metadata?.opacity,
                }}
                key={index}
              >
                <div className="sugarbook-pin-username">
                  {notice?.metadata?.username}:
                </div>
                <div
                  className="sugarbook-pin-message"
                  dangerouslySetInnerHTML={{
                    __html: notice?.metadata?.message,
                  }}
                ></div>
              </div>
            );
        })}
      </div>
    );
  }
};

export default SugarbookPinElement;
