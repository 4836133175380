// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Main Page variables
  videoCallSearchLists: [],
  videoCallRecentList: [],
  videoCallSearchPagination: null,
  videoCallRecentPagination: null,

  // General variables
  videoCallTab: 0,
  isPrivateStandbyMode: false,
  privateCallStandbyTimer: 0,
  privateCallCallingTimer: 60,
  privateCallWaitingRoomTimer: 300,
  isHunterMode: false,
  exitHunterMode: false,
  showVideoCallJoiningOverlay: false,
  showVideoCallStreamInitializingOverlay: false,
  showVideoCallEndingOverlay: false,
  standbyId: null,
  requestId: null,
  isCaller: null,
  callDurationRemaining: null,
  freeSecondsRemaining: null,
  endVideoCall: false,

  // TRTC variables
  callerUserSig: null,
  calleeUserSig: null,
  roomId: null,
  callStatus: "STANDBY",

  // Permissions variables
  cameraPermissions: false,
  microphonePermissions: false,
  permissionErrorMessage: null,

  // Devices variables
  allDevices: [],
  cameraDevices: [],
  microphoneDevices: [],
  selectedCameraDevice: "",
  selectedMicrophoneDevice: "",
  advancedCameraSettings: false,

  // Network variables
  networkQuality: 0, // 0, 1 = good, 2, 3 = poor, 4, 5 = bad
  uplinkQuality: 0,
  downlinkQuality: 0,

  // Chat variables
  videoCallChatMessages: [],

  // Emoji variable
  videoCallFloatingEmoji: null,

  // Gift Animation variables
  privateCallGiftAnimation: null,

  // Interval variables
  standbyTimerInterval: null,
  standbyKeepAliveInterval: null,
  standbyKeepAliveMinimizedInterval: null,
  roomKeepAliveInterval: null,
  callDurationRemainingInterval: null,
  freeSecondsRemainingInterval: null,
  privateCallCallingInterval: null,
  privateCallWaitingRoomInterval: null,
  isStandbyKeepAliveIntervalPaused: null,
  isRoomKeepAliveIntervalPaused: null,

  // Diamonds variables
  totalDiamonds: 0,
  callRequestDiamonds: 0,
  currentCallRequestDiamonds: 0,
  unclaimedDiamonds: 0,
  claimedDiamonds: 0,

  // Coins variables
  totalCoins: 0,

  // Calls variables
  callsAccepted: 0,

  // Rates variables
  callerCoins: 0,
  calleeCoinsPerMinute: 0,
  calleeDiamonds: 0,
  calleeRatePerMinute: 0,

  // Tips variables
  customizeTipRequestInfo: {},
  customizeTipRequestAcceptedInfo: {},

  // Charged Message variables
  isChargedMessage: null,
  chargedMessageCoinsReceived: null,
  chargedMessageDiamondsReceived: null,

  // Caller variables
  callerId: null,
  callerUsername: null,
  callerProfilePhoto: null,
  callerMembershipType: null,

  // Callee variables
  calleeId: null,
  calleeUsername: null,
  calleeProfilePhoto: null,
  calleeMembershipType: null,

  // Callee Current Video Call Summary variables
  calleeCurrentVideoCallSummary: {
    duration: 0,
    totalDiamondsEarned: 0,
    videoCallDiamondsEarned: 0,
    chargedMessagesDiamondsEarned: 0,
    tipsDiamondsEarned: 0,
    giftsDiamondsEarned: 0,
    actionsCompleted: 0,
    requestsFromDaddy: 0,
  },

  // Callee End Video Call Summary variables
  calleeEndVideoCallSummary: {
    standbyDuration: 0,
    callDuration: 0,
    totalStandbyDiamondsEarned: 0,
    totalDiamondsEarned: 0,
    standbyDiamondsEarned: 0,
    videoCallDiamondsEarned: 0,
    chargedMessagesDiamondsEarned: 0,
    tipsDiamondsEarned: 0,
    giftsDiamondsEarned: 0,
    actionCompleted: 0,
    requestsFromDaddy: 0,
    incomingCallRequests: [],
  },

  // Caller End Video Call Summary variables
  callerEndVideoCallSummary: {
    callDuration: 0,
    chargedMessagesSent: 0,
    giftsSent: 0,
    tipSent: 0,
    tipCoinsSent: 0,
    tipActionRequested: 0,
    tipActionCompleted: 0,
  },

  // Video Call History variables
  videoCallHistoryInfo: {},

  // Reference variables // Read Only
  inboxPrivateCallMinimumDuration: 120, // 120 seconds // 2 minutes

  // Scroll variables
  videoCallScrollYPosition: 0,
  videoCallScrollToTopPassthrough: {},

  // Utility variables
  disableSwipe: false,
  videoCallPrejoinPaymentPassthrough: {}, // Coin Package -> Video Call Pre Join
  videoCallPassthrough: {}, // Navbar -> Inbox Conversation
  callStandbyKeepAlivePassthrough: {},
  callRoomKeepAlivePassthrough: {},
  notifyEndVideoCallPassthrough: {},
  chargedMessageRefreshPassthrough: {},
  reinitializeCameraStreamPassthrough: {}, // Video Call Topbar => Video Call Caller/Callee Camera Feed
  changeCameraMicrophonePassthrough: null,
  swapVideoViewPassthrough: null, // mini, full
  toggleCameraPassthrough: null, // true, false
  toggleRemoteCameraPassthrough: null, // true, false
  isLocalCameraEnabled: null,
  isLocalMicrophoneEnabled: true,
  toggleMicrophonePassthrough: null, // true, false
  hideUiPassthrough: false, // true, false

  // API Response variables
  videoCallSettings: null,
};

export const privateCallSlice = createSlice({
  name: "privateCall",
  initialState,
  reducers: {
    // Main Page Functions
    updateVideoCallSearchLists: (state, action) => {
      const videoCallSearchListsData = [
        ...state.videoCallSearchLists,
        ...action.payload,
      ];
      state.videoCallSearchLists = videoCallSearchListsData;
    },
    updateVideoCallRecentList: (state, action) => {
      const videoCallRecentListData = [
        ...state.videoCallRecentList,
        ...action.payload,
      ];
      state.videoCallRecentList = videoCallRecentListData;
    },
    updateVideoCallSearchPagination: (state, action) => {
      state.videoCallSearchPagination = action.payload;
    },
    updateVideoCallRecentPagination: (state, action) => {
      state.videoCallRecentPagination = action.payload;
    },
    resetVideoCallSearchLists: (state) => {
      state.videoCallSearchLists = [];
      state.videoCallSearchPagination = null;
    },
    resetVideoCallRecentList: (state) => {
      state.videoCallRecentList = [];
      state.videoCallRecentPagination = null;
    },

    // General Functions
    updateVideoCallTab: (state, action) => {
      state.videoCallTab = action.payload;
    },
    updateIsPrivateStandbyMode: (state, action) => {
      state.isPrivateStandbyMode = action.payload;
    },
    startPrivateCallStandbyTimer: (state) => {
      state.privateCallStandbyTimer++;
    },
    startPrivateCallCallingTimer: (state) => {
      state.privateCallCallingTimer--;

      if (state.privateCallCallingTimer <= 0) {
        clearInterval(state.privateCallCallingInterval);
        state.privateCallCallingInterval = null;
      }
    },
    startPrivateCallWaitingRoomTimer: (state) => {
      state.privateCallWaitingRoomTimer--;

      if (state.privateCallWaitingRoomTimer <= 0) {
        clearInterval(state.privateCallWaitingRoomInterval);
        state.privateCallWaitingRoomInterval = null;
      }
    },
    startCallDurationRemainingTimer: (state) => {
      if (state.callDurationRemaining > 0) {
        state.callDurationRemaining--;
      }
    },
    startFreeSecondsRemainingTimer: (state) => {
      if (state.freeSecondsRemaining > 0) {
        state.freeSecondsRemaining--;
      }
    },
    updateIsHunterMode: (state, action) => {
      state.isHunterMode = action.payload;
    },
    updateExitHunterMode: (state, action) => {
      state.exitHunterMode = action.payload;
    },
    updateShowVideoCallJoiningOverlay: (state, action) => {
      state.showVideoCallJoiningOverlay = action.payload;
    },
    updateShowVideoCallStreamInitializingOverlay: (state, action) => {
      state.showVideoCallStreamInitializingOverlay = action.payload;
    },
    updateShowVideoCallEndingOverlay: (state, action) => {
      state.showVideoCallEndingOverlay = action.payload;
    },
    updateStandbyId: (state, action) => {
      state.standbyId = action.payload;
    },
    updateRequestId: (state, action) => {
      state.requestId = action.payload;
    },
    updateIsCaller: (state, action) => {
      state.isCaller = action.payload;
    },
    updateCallDurationRemaining: (state, action) => {
      state.callDurationRemaining = action.payload;
    },
    updateFreeSecondsRemaining: (state, action) => {
      state.freeSecondsRemaining = action.payload;
    },
    updateEndVideoCall: (state, action) => {
      state.endVideoCall = action.payload;
    },
    resetPrivateCallStandbyTimer: (state) => {
      state.privateCallStandbyTimer = 0;
    },
    resetPrivateCallCallingTimer: (state) => {
      state.privateCallCallingTimer = 60;
    },
    resetPrivateCallWaitingRoomTimer: (state) => {
      state.privateCallWaitingRoomTimer = 300;
    },
    resetCallDurationRemainingTimer: (state) => {
      state.callDurationRemaining = 0;
    },
    resetRequestId: (state) => {
      state.requestId = null;
    },

    // TRTC Functions
    updateCallerUserSig: (state, action) => {
      state.callerUserSig = action.payload;
    },
    updateCalleeUserSig: (state, action) => {
      state.calleeUserSig = action.payload;
    },
    updateRoomId: (state, action) => {
      state.roomId = action.payload;
    },
    updateCallStatus: (state, action) => {
      state.callStatus = action.payload;
    },
    clearCallerUserSig: (state) => {
      state.callerUserSig = null;
    },
    clearCalleeUserSig: (state) => {
      state.calleeUserSig = null;
    },
    clearRoomId: (state) => {
      state.roomId = null;
    },

    // Permissions Functions
    updateCameraPermissions: (state, action) => {
      state.cameraPermissions = action.payload;
    },
    updateMicrophonePermissions: (state, action) => {
      state.microphonePermissions = action.payload;
    },
    updatePermissionErrorMessage: (state, action) => {
      state.permissionErrorMessage = action.payload;
    },

    // Devices Functions
    updateAllDevices: (state, action) => {
      state.allDevices = action.payload;
    },
    updateCameraDevices: (state, action) => {
      state.cameraDevices = action.payload;
    },
    updateMicrophoneDevices: (state, action) => {
      state.microphoneDevices = action.payload;
    },
    updateSelectedCameraDevice: (state, action) => {
      state.selectedCameraDevice = action.payload;
    },
    updateSelectedMicrophoneDevice: (state, action) => {
      state.selectedMicrophoneDevice = action.payload;
    },
    updateAdvancedCameraSettings: (state, action) => {
      state.advancedCameraSettings = action.payload;
    },

    // Network Functions
    updateNetworkQuality: (state, action) => {
      state.networkQuality = action.payload;
    },
    updateUplinkQualiy: (state, action) => {
      state.uplinkQuality = action.payload;
    },
    updateDownlinkQuality: (state, action) => {
      state.downlinkQuality = action.payload;
    },

    // Chat Functions
    updateVideoCallChatMessages: (state, action) => {
      state.videoCallChatMessages.push(action.payload);
    },
    clearVideoCallChatMessages: (state, action) => {
      state.videoCallChatMessages = [];
    },

    // Emoji Functions
    updateVideoCallFloatingEmoji: (state, action) => {
      state.videoCallFloatingEmoji = action.payload;
    },

    // Gift Animation Functions
    updatePrivateCallGiftAnimation: (state, action) => {
      state.privateCallGiftAnimation = action.payload;
    },

    // Interval Functions
    updateStandbyTimerInterval: (state, action) => {
      state.standbyTimerInterval = action.payload;
    },
    updateStandbyKeepAliveInterval: (state, action) => {
      if (state.standbyKeepAliveInterval) return;

      state.standbyKeepAliveInterval = action.payload;
    },
    updateStandbyKeepAliveMinimizedInterval: (state, action) => {
      if (state.standbyKeepAliveMinimizedInterval) return;

      state.standbyKeepAliveMinimizedInterval = action.payload;
    },
    updateRoomKeepAliveInterval: (state, action) => {
      if (state.roomKeepAliveInterval) return;

      state.roomKeepAliveInterval = action.payload;
    },
    updateCallDurationRemainingInterval: (state, action) => {
      if (state.callDurationRemainingInterval) return;

      state.callDurationRemainingInterval = action.payload;
    },
    updateFreeSecondsRemainingInterval: (state, action) => {
      if (state.freeSecondsRemainingInterval) return;

      state.freeSecondsRemainingInterval = action.payload;
    },
    updatePrivateCallCallingInterval: (state, action) => {
      if (state.privateCallCallingInterval) return;

      state.privateCallCallingInterval = action.payload;
    },
    updatePrivateCallWaitingRoomInterval: (state, action) => {
      if (state.privateCallWaitingRoomInterval) return;

      state.privateCallWaitingRoomInterval = action.payload;
    },
    updateIsStandbyKeepAliveIntervalPaused: (state, action) => {
      state.isStandbyKeepAliveIntervalPaused = action.payload;
    },
    updateIsRoomKeepAliveIntervalPaused: (state, action) => {
      state.isRoomKeepAliveIntervalPaused = action.payload;
    },
    clearStandbyTimerInterval: (state) => {
      clearInterval(state.standbyTimerInterval);
      state.standbyTimerInterval = null;
    },
    clearStandbyKeepAliveInterval: (state) => {
      clearInterval(state.standbyKeepAliveInterval);
      state.standbyKeepAliveInterval = null;
    },
    clearStandbyKeepAliveMinimizedInterval: (state) => {
      clearInterval(state.standbyKeepAliveMinimizedInterval);
      state.standbyKeepAliveMinimizedInterval = null;
    },
    clearRoomKeepAliveInterval: (state) => {
      clearInterval(state.roomKeepAliveInterval);
      state.roomKeepAliveInterval = null;
    },
    clearCallDurationRemainingInterval: (state) => {
      clearInterval(state.callDurationRemainingInterval);
      state.callDurationRemainingInterval = null;
    },
    clearFreeSecondsRemainingInterval: (state) => {
      clearInterval(state.freeSecondsRemainingInterval);
      state.freeSecondsRemainingInterval = null;
    },
    clearPrivateCallCallingInterval: (state) => {
      clearInterval(state.privateCallCallingInterval);
      state.privateCallCallingInterval = null;
    },
    clearPrivateCallWaitingRoomInterval: (state) => {
      clearInterval(state.privateCallWaitingRoomInterval);
      state.privateCallWaitingRoomInterval = null;
    },

    // Diamonds Functions
    updateTotalDiamonds: (state, action) => {
      state.totalDiamonds = action.payload;
    },
    updateCallRequestDiamonds: (state, action) => {
      state.callRequestDiamonds = action.payload;
    },
    updateCurrentCallRequestDiamonds: (state, action) => {
      state.currentCallRequestDiamonds = action.payload;
    },
    updateUnclaimedDiamonds: (state, action) => {
      state.unclaimedDiamonds = action.payload;
    },
    updateClaimedDiamonds: (state, action) => {
      state.claimedDiamonds = action.payload;
    },

    // Coins Functions
    updateTotalCoins: (state, action) => {
      state.totalCoins = action.payload;
    },

    // Rates Functions
    updateCallerCoins: (state, action) => {
      state.callerCoins = action.payload;
    },
    updateCalleeCoinsPerMinute: (state, action) => {
      state.calleeCoinsPerMinute = action.payload;
    },
    updateCalleeDiamonds: (state, action) => {
      state.calleeDiamonds = action.payload;
    },
    updateCalleeRatePerMinute: (state, action) => {
      state.calleeRatePerMinute = action.payload;
    },

    // Tips Functions
    updateCustomizeTipRequestInfo: (state, action) => {
      state.customizeTipRequestInfo = action.payload;
    },
    updateCustomizeTipRequestAcceptedInfo: (state, action) => {
      state.customizeTipRequestAcceptedInfo = action.payload;
    },

    // Charged Message Functions
    updateIsChargedMessage: (state, action) => {
      state.isChargedMessage = action.payload;
    },
    updateChargedMessageCoinsReceived: (state, action) => {
      state.chargedMessageCoinsReceived = action.payload;
    },
    updateChargedMessageDiamondsReceived: (state, action) => {
      state.chargedMessageDiamondsReceived = action.payload;
    },

    // Calls Functions
    updateCallsAccepted: (state, action) => {
      state.callsAccepted = action.payload;
    },

    // Caller Functions
    updateCallerId: (state, action) => {
      state.callerId = action.payload;
    },
    updateCallerUsername: (state, action) => {
      state.callerUsername = action.payload;
    },
    updateCallerProfilePhoto: (state, action) => {
      state.callerProfilePhoto = action.payload;
    },
    updateCallerMembershipType: (state, action) => {
      state.callerMembershipType = action.payload;
    },

    // Callee Functions
    updateCalleeId: (state, action) => {
      state.calleeId = action.payload;
    },
    updateCalleeUsername: (state, action) => {
      state.calleeUsername = action.payload;
    },
    updateCalleeProfilePhoto: (state, action) => {
      state.calleeProfilePhoto = action.payload;
    },
    updateCalleeMembershipType: (state, action) => {
      state.calleeMembershipType = action.payload;
    },

    //  Callee Current Video Call Summary Functions
    updateCalleeCurrentSummaryDuration: (state, action) => {
      state.calleeCurrentVideoCallSummary.duration = action.payload;
    },
    updateCalleeCurrentSummaryTotalDiamondsEarned: (state, action) => {
      state.calleeCurrentVideoCallSummary.totalDiamondsEarned = action.payload;
    },
    updateCalleeCurrentSummaryVideoCallDiamondsEarned: (state, action) => {
      state.calleeCurrentVideoCallSummary.videoCallDiamondsEarned =
        action.payload;
    },
    updateCalleeCurrentSummaryChargedMessagesDiamondsEarned: (
      state,
      action
    ) => {
      state.calleeCurrentVideoCallSummary.chargedMessagesDiamondsEarned =
        action.payload;
    },
    updateCalleeCurrentSummaryTipsDiamondsEarned: (state, action) => {
      state.calleeCurrentVideoCallSummary.tipsDiamondsEarned = action.payload;
    },
    updateCalleeCurrentSummaryGiftsDiamondsEarned: (state, action) => {
      state.calleeCurrentVideoCallSummary.giftsDiamondsEarned = action.payload;
    },
    updateCalleeCurrentSummaryActionsCompleted: (state, action) => {
      state.calleeCurrentVideoCallSummary.actionsCompleted = action.payload;
    },
    updateCalleeCurrentSummaryRequestsFromDaddy: (state, action) => {
      state.calleeCurrentVideoCallSummary.requestsFromDaddy = action.payload;
    },

    // Callee End Video Call Summary Functions
    updateCalleeEndVideoCallSummaryData: (state, action) => {
      state.calleeEndVideoCallSummary.standbyDuration =
        action.payload.standbyDuration;
      state.calleeEndVideoCallSummary.callDuration =
        action.payload.callDuration;
      state.calleeEndVideoCallSummary.totalStandbyDiamondsEarned =
        action.payload.totalStandbyDiamondsEarned;
      state.calleeEndVideoCallSummary.totalDiamondsEarned =
        action.payload.totalDiamondsEarned;
      state.calleeEndVideoCallSummary.standbyDiamondsEarned =
        action.payload.standbyDiamondsEarned;
      state.calleeEndVideoCallSummary.videoCallDiamondsEarned =
        action.payload.videoCallDiamondsEarned;
      state.calleeEndVideoCallSummary.chargedMessagesDiamondsEarned =
        action.payload.chargedMessagesDiamondsEarned;
      state.calleeEndVideoCallSummary.tipsDiamondsEarned =
        action.payload.tipsDiamondsEarned;
      state.calleeEndVideoCallSummary.giftsDiamondsEarned =
        action.payload.giftsDiamondsEarned;
      state.calleeEndVideoCallSummary.actionCompleted =
        action.payload.actionCompleted;
      state.calleeEndVideoCallSummary.requestsFromDaddy =
        action.payload.requestsFromDaddy;
      state.calleeEndVideoCallSummary.incomingCallRequests =
        action.payload.incomingCallRequests;
    },
    resetCalleeEndVideoCallSummaryData: (state) => {
      state.calleeEndVideoCallSummary.standbyDuration = 0;
      state.calleeEndVideoCallSummary.callDuration = 0;
      state.calleeEndVideoCallSummary.totalStandbyDiamondsEarned = 0;
      state.calleeEndVideoCallSummary.totalDiamondsEarned = 0;
      state.calleeEndVideoCallSummary.standbyDiamondsEarned = 0;
      state.calleeEndVideoCallSummary.videoCallDiamondsEarned = 0;
      state.calleeEndVideoCallSummary.chargedMessagesDiamondsEarned = 0;
      state.calleeEndVideoCallSummary.tipsDiamondsEarned = 0;
      state.calleeEndVideoCallSummary.giftsDiamondsEarned = 0;
      state.calleeEndVideoCallSummary.actionCompleted = 0;
      state.calleeEndVideoCallSummary.requestsFromDaddy = 0;
      state.calleeEndVideoCallSummary.incomingCallRequests = 0;
    },

    // Caller End Video Call Summary Functions
    updateCallerEndVideoCallSummaryData: (state, action) => {
      state.callerEndVideoCallSummary.callDuration =
        action.payload.callDuration;
      state.callerEndVideoCallSummary.chargedMessagesSent =
        action.payload.chargedMessagesSent;
      state.callerEndVideoCallSummary.giftsSent = action.payload.giftsSent;
      state.callerEndVideoCallSummary.tipSent = action.payload.tipSent;
      state.callerEndVideoCallSummary.tipCoinsSent =
        action.payload.tipCoinsSent;
      state.callerEndVideoCallSummary.tipActionRequested =
        action.payload.tipActionRequested;
      state.callerEndVideoCallSummary.tipActionCompleted =
        action.payload.tipActionCompleted;
    },
    resetCallerEndVideoCallSummaryData: (state) => {
      state.callerEndVideoCallSummary.callDuration = 0;
      state.callerEndVideoCallSummary.giftsSent = 0;
      state.callerEndVideoCallSummary.tipSent = 0;
      state.callerEndVideoCallSummary.tipCoinsSent = 0;
      state.callerEndVideoCallSummary.tipActionRequested = 0;
      state.callerEndVideoCallSummary.tipActionCompleted = 0;
    },

    // Video Call History Functions
    updateVideoCallHistoryInfo: (state, action) => {
      state.videoCallHistoryInfo = action.payload;
    },

    // Scroll Functions
    updateVideoCallScrollYPosition: (state, action) => {
      state.videoCallScrollYPosition = action.payload;
    },
    updateVideoCallScrollToTopPassthrough: (state, action) => {
      state.videoCallScrollToTopPassthrough = action.payload;
    },

    // Utility Functions
    resetPrivateCallStore: () => {
      return { ...initialState };
    },
    updateLocalVideoCallList: (state, action) => {
      const idInt = action.payload.idInt;
      const privatePhotoRequested = action.payload.privatePhotoRequested;
      const isBlocked = action.payload.isBlocked;
      const isFavorited = action.payload.isFavorited;

      const videoCallSearchListsData = [...state.videoCallSearchLists];

      for (let i = 0; i < videoCallSearchListsData.length; i++) {
        if (videoCallSearchListsData[i].id_int === idInt) {
          if (privatePhotoRequested !== null) {
            videoCallSearchListsData[i].interaction.private_photo_requested =
              privatePhotoRequested;
          }

          if (isBlocked !== null) {
            videoCallSearchListsData[i].interaction.is_blocked = isBlocked;
          }

          if (isFavorited !== null) {
            videoCallSearchListsData[i].interaction.is_favorited = isFavorited;
          }
          break;
        }
      }

      state.videoCallSearchLists = videoCallSearchListsData;
    },
    updateDisableSwipe: (state, action) => {
      state.disableSwipe = action.payload;
    },
    updateVideoCallPrejoinPaymentPassthrough: (state, action) => {
      state.videoCallPrejoinPaymentPassthrough = action.payload;
    },
    updateVideoCallPassthrough: (state, action) => {
      // Navbar -> ConversationList
      state.videoCallPassthrough = action.payload;
    },
    updateCallStandbyKeepAlivePassthrough: (state, action) => {
      if (state.isStandbyKeepAliveIntervalPaused) return;

      state.callStandbyKeepAlivePassthrough = action.payload;
    },
    updateCallRoomKeepAlivePassthrough: (state, action) => {
      if (state.isRoomKeepAliveIntervalPaused) return;

      state.callRoomKeepAlivePassthrough = action.payload;
    },
    updateForceCallRoomKeepAlivePassthrough: (state, action) => {
      state.callRoomKeepAlivePassthrough = action.payload;
    },
    updateNotifyEndVideoCallPassthrough: (state, action) => {
      state.notifyEndVideoCallPassthrough = action.payload;
    },
    updateChargedMessageRefreshPassthrough: (state, action) => {
      state.chargedMessageRefreshPassthrough = action.payload;
    },
    updateReinitializeCameraStreamPassthrough: (state, action) => {
      state.reinitializeCameraStreamPassthrough = action.payload;
    },
    updateChangeCameraMicrophonePassthrough: (state) => {
      state.changeCameraMicrophonePassthrough = {};
    },
    updateSwapVideoViewPassthrough: (state, action) => {
      state.swapVideoViewPassthrough = action.payload;
    },
    updateToggleCameraPassthrough: (state, action) => {
      state.toggleCameraPassthrough = action.payload;
    },
    updateToggleRemoteCameraPassthrough: (state, action) => {
      state.toggleRemoteCameraPassthrough = action.payload;
    },
    updateIsLocalCameraEnabled: (state, action) => {
      state.isLocalCameraEnabled = action.payload;
    },
    updateIsLocalMicrophoneEnabled: (state, action) => {
      state.isLocalMicrophoneEnabled = action.payload;
    },
    updateToggleMicrophonePassthrough: (state, action) => {
      state.toggleMicrophonePassthrough = action.payload;
    },
    updateHideUiPassthrough: (state, action) => {
      state.hideUiPassthrough = action.payload;
    },
    onEndCall: (state) => {
      state.showVideoCallEndingOverlay = true;
    },

    // API Response Functions
    updateVideoCallSettings: (state, action) => {
      state.videoCallSettings = action.payload;
    },
  },
});

export const {
  // Main Page Functions
  updateVideoCallSearchLists,
  updateVideoCallRecentList,
  updateVideoCallSearchPagination,
  updateVideoCallRecentPagination,
  resetVideoCallSearchLists,
  resetVideoCallRecentList,

  // General Functions
  updateVideoCallTab,
  updateIsPrivateStandbyMode,
  startPrivateCallStandbyTimer,
  startPrivateCallCallingTimer,
  startPrivateCallWaitingRoomTimer,
  startCallDurationRemainingTimer,
  startFreeSecondsRemainingTimer,
  updateIsHunterMode,
  updateExitHunterMode,
  updateShowVideoCallJoiningOverlay,
  updateShowVideoCallStreamInitializingOverlay,
  updateShowVideoCallEndingOverlay,
  updateStandbyId,
  updateRequestId,
  updateIsCaller,
  updateCallDurationRemaining,
  updateFreeSecondsRemaining,
  updateEndVideoCall,
  resetPrivateCallStandbyTimer,
  resetPrivateCallCallingTimer,
  resetPrivateCallWaitingRoomTimer,
  resetCallDurationRemainingTimer,
  resetRequestId,

  // TRTC Functions
  updateCallerUserSig,
  updateCalleeUserSig,
  updateRoomId,
  updateCallStatus,
  clearCallerUserSig,
  clearCalleeUserSig,
  clearRoomId,

  // Permissions Functions
  updateCameraPermissions,
  updateMicrophonePermissions,
  updatePermissionErrorMessage,

  // Devices Functions
  updateAllDevices,
  updateCameraDevices,
  updateMicrophoneDevices,
  updateSelectedCameraDevice,
  updateSelectedMicrophoneDevice,
  updateAdvancedCameraSettings,

  // Network Functions
  updateNetworkQuality,
  updateUplinkQualiy,
  updateDownlinkQuality,

  // Chat Functions
  updateVideoCallChatMessages,
  clearVideoCallChatMessages,

  // Emoji Functions
  updateVideoCallFloatingEmoji,

  // Gift Animation Functions
  updatePrivateCallGiftAnimation,

  // Interval Functions
  updateStandbyTimerInterval,
  updateStandbyKeepAliveInterval,
  updateStandbyKeepAliveMinimizedInterval,
  updateRoomKeepAliveInterval,
  updateCallDurationRemainingInterval,
  updateFreeSecondsRemainingInterval,
  updatePrivateCallCallingInterval,
  updatePrivateCallWaitingRoomInterval,
  updateIsStandbyKeepAliveIntervalPaused,
  updateIsRoomKeepAliveIntervalPaused,
  clearStandbyTimerInterval,
  clearStandbyKeepAliveInterval,
  clearStandbyKeepAliveMinimizedInterval,
  clearRoomKeepAliveInterval,
  clearCallDurationRemainingInterval,
  clearFreeSecondsRemainingInterval,
  clearPrivateCallCallingInterval,
  clearPrivateCallWaitingRoomInterval,

  // Diamonds Functions
  updateTotalDiamonds,
  updateCallRequestDiamonds,
  updateCurrentCallRequestDiamonds,
  updateUnclaimedDiamonds,
  updateClaimedDiamonds,

  // Coins Functions
  updateTotalCoins,

  // Rates Functions
  updateCallerCoins,
  updateCalleeCoinsPerMinute,
  updateCalleeDiamonds,
  updateCalleeRatePerMinute,

  // Tips Functions
  updateCustomizeTipRequestInfo,
  updateCustomizeTipRequestAcceptedInfo,

  // Charged Message Functions
  updateIsChargedMessage,
  updateChargedMessageCoinsReceived,
  updateChargedMessageDiamondsReceived,

  // Calls Functions
  updateCallsAccepted,

  // Caller Functions
  updateCallerId,
  updateCallerUsername,
  updateCallerProfilePhoto,
  updateCallerMembershipType,

  // Callee Functions
  updateCalleeId,
  updateCalleeUsername,
  updateCalleeProfilePhoto,
  updateCalleeMembershipType,

  // Callee Current Video Call Summary Functions
  updateCalleeCurrentSummaryDuration,
  updateCalleeCurrentSummaryTotalDiamondsEarned,
  updateCalleeCurrentSummaryVideoCallDiamondsEarned,
  updateCalleeCurrentSummaryChargedMessagesEarned,
  updateCalleeCurrentSummaryTipsDiamondsEarned,
  updateCalleeCurrentSummaryGiftsDiamondsEarned,
  updateCalleeCurrentSummaryActionsCompleted,
  updateCalleeCurrentSummaryRequestsFromDaddy,

  // Callee End Video Call Summary Functions
  updateCalleeEndVideoCallSummaryData,
  resetCalleeEndVideoCallSummaryData,

  // Caller End Video Call Summary Functions
  updateCallerEndVideoCallSummaryData,
  resetCallerEndVideoCallSummaryData,

  // Video Call History Functions
  updateVideoCallHistoryInfo,

  // Scroll Functions
  updateVideoCallScrollYPosition,
  updateVideoCallScrollToTopPassthrough,

  // Utility Functions
  resetPrivateCallStore,
  updateLocalVideoCallList,
  updateDisableSwipe,
  updateVideoCallPrejoinPaymentPassthrough,
  updateVideoCallPassthrough,
  updateCallStandbyKeepAlivePassthrough,
  updateCallRoomKeepAlivePassthrough,
  updateForceCallRoomKeepAlivePassthrough,
  updateNotifyEndVideoCallPassthrough,
  updateChargedMessageRefreshPassthrough,
  updateReinitializeCameraStreamPassthrough,
  updateChangeCameraMicrophonePassthrough,
  updateSwapVideoViewPassthrough,
  updateToggleCameraPassthrough,
  updateToggleRemoteCameraPassthrough,
  updateIsLocalCameraEnabled,
  updateIsLocalMicrophoneEnabled,
  updateToggleMicrophonePassthrough,
  updateHideUiPassthrough,
  onEndCall,

  // API Response Functions
  updateFreeMinutes,
  updateVideoCallSettings,
} = privateCallSlice.actions;

export default privateCallSlice.reducer;
