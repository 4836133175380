// General
import "./verified-profile-badge.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../services/data.service";
// Redux
import { useDispatch } from "react-redux";
import {
  updateOnboardingSugarbookVerifiedDialog,
  updateVerifiedProfileDescriptionDialog,
} from "../../../../redux/store/dialogStore";
// Material UI
import { Tooltip, useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const VerifiedProfileBadge = (props) => {
  const { verifiedProfile = false, className } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 455px)");

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Event Handlers | Button
  const onOpenVerifiedProfileDialog = () => {
    if (
      !getProfileData?.data?.verifications?.facial?.verified ||
      getProfileData?.data?.verifications?.facial?.verification_display === true
    ) {
      dispatch(updateOnboardingSugarbookVerifiedDialog(true));
    } else {
      if (verifiedProfile) {
        dispatch(updateVerifiedProfileDescriptionDialog(true));
      }
    }
  };

  // Custom Hooks Functions
  const getIcon = IconManager();

  if (verifiedProfile) {
    return (
      <Tooltip
        id="custom-white-tooltip"
        title={
          <div id="verified-profile-tooltip-container">
            <div className="verified-profile-title-container">
              <div className="verified-profile-badge-container">
                {getIcon("verifiedProfileBadge", "verified-profile-badge")}
              </div>

              <div className="verified-profile-title">
                {t("popup.verified_profile_title")}
              </div>
            </div>

            <div className="verified-profile-description-container">
              {t("popup.verified_caller_desc")}
            </div>
          </div>
        }
      >
        <div
          className="verified-profile-badge-container"
          onClick={onOpenVerifiedProfileDialog}
        >
          {getIcon("verifiedProfileBadge", className)}
        </div>
      </Tooltip>
    );
  } else if (verifiedProfile === false) {
    return (
      <div
        className="unverified-profile-badge-container"
        onClick={onOpenVerifiedProfileDialog}
      >
        {getIcon("unverifiedProfileBadge", className)}
      </div>
    );
  }
};

export default VerifiedProfileBadge;
