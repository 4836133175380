// General
import "./join.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetAioQuery,
  useLazyGetAuthSignupOptionsQuery,
} from "../../services/data.service";
// Static Data
import routeConst from "../../const/routeConst";
// Enums
import user from "../../enums/user";
import userConst from "../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateGender,
  updateWantToBe,
  updateLookingFor,
  updateInterest,
} from "../../redux/store/signupStore";
import {
  updateWhatsARoleDialog,
  updateChooseYourRoleDialog,
} from "../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Button, useMediaQuery } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../shared/elements/spinner/spinner";

const Join = () => {
  // API variables
  const [
    getAio,
    {
      data: getAioData,
      error: getAioErrorData,
      isFetching: getAioFetching,
      isLoading: getAioLoading,
      isSuccess: getAioSuccess,
      isError: getAioError,
    },
  ] = useLazyGetAioQuery();
  const [
    getAuthSignupOptions,
    {
      data: getAuthSignupOptionsData,
      error: getAuthSignupOptionsErrorData,
      isFetching: getAuthSignupOptionsFetching,
      isLoading: getAuthSignupOptionsLoading,
      isSuccess: getAuthSignupOptionsSuccess,
      isError: getAuthSignupOptionsError,
    },
  ] = useLazyGetAuthSignupOptionsQuery();

  // Redux variables
  const gender = useSelector((state) => state.signup.gender);
  const wantToBe = useSelector((state) => state.signup.wantToBe);
  const lookingFor = useSelector((state) => state.signup.lookingFor);
  const interest = useSelector((state) => state.signup.interest);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-6",
      },
    });

    getAio();
    getAuthSignupOptions(null, false);
  }, []);

  // Event Handlers | Button
  const onNextPage = () => {
    if (!isFormValid()) return;
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Join-Page-To-Account-Signup-Page",
      },
    });

    onNavigate(routeConst.accountSignup.path);
  };
  const onOpenRoleInfoDialog = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-6-6.1",
      },
    });

    dispatch(updateWhatsARoleDialog(true));
  };
  const onSwitchGender = () => {
    if (
      getAuthSignupOptionsData?.gender?.some(
        (item) => item.value === user.gender.LGBTQ
      )
    ) {
      if (gender === user.gender.MAN) {
        dispatch(updateGender(user.gender.WOMAN));
        dispatch(updateLookingFor(user.lookingFor.SUGARDADDY));
        dispatch(updateInterest(userConst.interest.man.value));
      } else if (gender === user.gender.WOMAN) {
        dispatch(updateGender(user.gender.LGBTQ));
        dispatch(updateLookingFor(user.lookingFor.SUGARBABY));
        dispatch(updateInterest(""));
      } else if (gender === user.gender.LGBTQ) {
        dispatch(updateGender(user.gender.MAN));
        dispatch(updateLookingFor(user.lookingFor.SUGARBABY));
        dispatch(updateInterest(userConst.interest.woman.value));
      }
    } else {
      if (gender === user.gender.MAN) {
        dispatch(updateGender(user.gender.WOMAN));
        dispatch(updateLookingFor(user.lookingFor.SUGARDADDY));
        dispatch(updateInterest(userConst.interest.man.value));
      } else if (gender === user.gender.WOMAN) {
        dispatch(updateGender(user.gender.MAN));
        dispatch(updateLookingFor(user.lookingFor.SUGARBABY));
        dispatch(updateInterest(userConst.interest.woman.value));
      }
    }
  };
  const onSwitchRole = () => {
    if (gender === user.gender.LGBTQ) {
      dispatch(updateInterest(""));
      if (wantToBe === userConst.wantToBe.sugardaddy.value) {
        dispatch(updateWantToBe(userConst.wantToBe.sugarbaby.value));
      } else if (wantToBe === userConst.wantToBe.sugarbaby.value) {
        dispatch(updateWantToBe(userConst.wantToBe.sugardaddy.value));
      }
    }
  };

  // Event Handlers | MUI Select
  const onGenderSelect = (event) => {
    // dispatch(updateGender(event));

    if (event === user.gender.MAN) {
      dispatch(updateGender(event));
      dispatch(updateLookingFor(user.lookingFor.SUGARBABY));
      dispatch(updateInterest(userConst.interest.woman.value));
    } else if (event === user.gender.WOMAN) {
      dispatch(updateGender(event));
      dispatch(updateLookingFor(user.lookingFor.SUGARDADDY));
      dispatch(updateInterest(userConst.interest.man.value));
    } else if (event === user.gender.LGBTQ) {
      dispatch(updateChooseYourRoleDialog(true));
      // dispatch(updateWantToBe(userConst.wantToBe.sugardaddy.value));
      // dispatch(updateLookingFor(user.lookingFor.SUGARDADDY));
    }
  };
  const onLookingForSelect = (event) => {
    if (gender !== user.gender.LGBTQ) {
      dispatch(updateLookingFor(event));
    } else {
      if (wantToBe === userConst.wantToBe.sugardaddy.value) {
        if (interest === userConst.interest.man.value) {
          dispatch(updateInterest(userConst.interest.woman.value));
        } else if (interest === userConst.interest.woman.value) {
          dispatch(updateInterest(userConst.interest.man.value));
        }
      } else if (wantToBe === userConst.wantToBe.sugarbaby.value) {
        if (interest === userConst.interest.man.value) {
          dispatch(updateInterest(userConst.interest.woman.value));
        } else if (interest === userConst.interest.woman.value) {
          dispatch(updateInterest(userConst.interest.man.value));
        }
      }
    }
  };
  const onInterestSelect = (event) => {
    dispatch(updateInterest(event));
  };

  // Utility Functions
  const isFormValid = () => {
    let valid = false;
    if (gender !== "" && lookingFor !== "" && interest !== "") {
      valid = true;
    }

    return valid;
  };
  const getGender = (gender) => {
    if (gender === user.gender.MAN) {
      return t("signup.opt_man");
    } else if (gender === user.gender.WOMAN) {
      return t("signup.opt_woman");
    } else if (gender === user.gender.LGBTQ) {
      return t("signup.opt_non_binary");
    }
  };
  const getRoles = (gender, lookingFor) => {
    if (gender === "man") {
      if (lookingFor === "sugardaddy") {
        return "sugarbaby";
      } else if (lookingFor === "sugarbaby") {
        return "sugardaddy";
      }
    } else if (gender === "woman") {
      if (lookingFor === "sugardaddy") {
        return "sugarbaby";
      } else if (lookingFor === "sugarbaby") {
        return "sugardaddy";
      }
    } else if (gender === user.gender.LGBTQ) {
      if (wantToBe === userConst.wantToBe.sugardaddy.value) {
        return t(userConst.wantToBe.sugardaddy.title);
      } else if (wantToBe === userConst.wantToBe.sugarbaby.value) {
        return t(userConst.wantToBe.sugarbaby.title);
      }
    }
  };

  return (
    <div id="join-page" className="join-container">
      <div className="max-width-container">
        <div className="navbar-container">
          <div
            className="back-button"
            onClick={() => onNavigate(routeConst.login.path)}
          >
            {getIcon("signupBackIcon", "signup-back-icon")}
          </div>
        </div>

        <div className="join-body-container">
          {getAuthSignupOptionsFetching || getAuthSignupOptionsLoading ? (
            <Spinner color={"white-spinner"} />
          ) : getAuthSignupOptionsSuccess ? (
            <div className="join-body-success-container">
              {!gender && (
                <div className="gender-select-description-container">
                  <div className="where-romance-meets-finance-mobile-label">
                    <Trans
                      i18nKey={"signup.join_title"}
                      components={{
                        br: <br />,
                      }}
                    />
                  </div>
                </div>
              )}

              {gender && (
                <div className="looking-for-select-description-container">
                  <div className="who-are-you-looking-for-label">
                    {t("signup.who_are_you_looking_for")}
                  </div>
                </div>
              )}

              <div className="i-am-container">
                <div className="i-am-label">
                  <div className="i-am-a-label">
                    {gender ? t("signup.i_am_a_plain") : t("signup.i_am_a")}
                  </div>
                  <div className="selected-gender">{getGender(gender)}</div>

                  {gender && (
                    <div
                      className="switch-gender-container"
                      onClick={onSwitchGender}
                    >
                      {getIcon("switchIcon", "switch-icon")}
                      <div className="switch-gender-label">
                        {t("common.switch")}
                      </div>
                    </div>
                  )}
                </div>

                {gender === user.gender.LGBTQ && (
                  <div className="non-binary-gender-options-container">
                    <div className="i-am-a-label">
                      {gender ? t("signup.i_am_a_plain") : t("signup.i_am_a")}
                    </div>
                    <div className="selected-gender">{getRoles(gender)}</div>

                    {gender && (
                      <div
                        className="switch-role-container"
                        onClick={onSwitchRole}
                      >
                        {getIcon("switchIcon", "switch-icon")}
                        <div className="switch-gender-label">
                          {t("common.switch")}
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {!gender && (
                  <div className="i-am-a-button-container">
                    {getAuthSignupOptionsData?.gender?.map((item, index) => (
                      <div
                        className={`man-label ${
                          item?.value === gender ? "selected" : ""
                        }`}
                        onClick={() => onGenderSelect(item?.value)}
                        key={index}
                      >
                        {item?.label}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {gender && (
                <div className="looking-for-container">
                  <div className="looking-for-label-container">
                    <div className="looking-for-label">
                      {t("signup.i_am_looking_for_a")}
                    </div>

                    <div className="role-info" onClick={onOpenRoleInfoDialog}>
                      {getIcon("helpIcon", "help-icon")}
                    </div>
                  </div>

                  <div className="looking-for-button-container">
                    {gender === user.gender.MAN && (
                      <div className="selection-container">
                        <div
                          className={`body-label ${
                            lookingFor === "sugarbaby" ? "selected" : ""
                          }`}
                          onClick={() =>
                            onLookingForSelect(user.lookingFor.SUGARBABY)
                          }
                        >
                          {t("signup.opt_sugar_baby")}
                        </div>
                        {false && (
                          <div
                            className={`body-label ${
                              lookingFor === "sugardaddy" ? "selected" : ""
                            }`}
                            onClick={() =>
                              onLookingForSelect(user.lookingFor.SUGARMOMMY)
                            }
                          >
                            {t("signup.opt_sugar_mommy")}
                          </div>
                        )}
                      </div>
                    )}

                    {gender === user.gender.WOMAN && (
                      <div className="selection-container">
                        <div
                          className={`body-label ${
                            lookingFor === "sugardaddy" ? "selected" : ""
                          }`}
                          onClick={() =>
                            onLookingForSelect(user.lookingFor.SUGARDADDY)
                          }
                        >
                          {t("signup.opt_sugar_daddy")}
                        </div>
                        {false && (
                          <div
                            className={`body-label ${
                              lookingFor === "sugarbaby" ? "selected" : ""
                            }`}
                            onClick={() =>
                              onLookingForSelect(user.lookingFor.MALE_SUGARBABY)
                            }
                          >
                            {t("signup.opt_male_sugar_baby")}
                          </div>
                        )}
                      </div>
                    )}

                    {gender === user.gender.LGBTQ &&
                      wantToBe === userConst.wantToBe.sugardaddy.value && (
                        <div className="selection-container">
                          <div
                            className={`body-label ${
                              interest === userConst.interest.man.value
                                ? "selected"
                                : ""
                            }`}
                            onClick={() =>
                              onInterestSelect(userConst.interest.man.value)
                            }
                          >
                            {t("common.gay")}
                          </div>

                          <div
                            className={`body-label ${
                              interest === userConst.interest.woman.value
                                ? "selected"
                                : ""
                            }`}
                            onClick={() =>
                              onInterestSelect(userConst.interest.woman.value)
                            }
                          >
                            {t("common.lesbian")}
                          </div>
                        </div>
                      )}

                    {gender === user.gender.LGBTQ &&
                      wantToBe === userConst.wantToBe.sugarbaby.value && (
                        <div className="selection-container">
                          <div
                            className={`body-label ${
                              interest === userConst.interest.man.value
                                ? "selected"
                                : ""
                            }`}
                            onClick={() =>
                              onInterestSelect(userConst.interest.man.value)
                            }
                          >
                            {t("common.gay")}
                          </div>

                          <div
                            className={`body-label ${
                              interest === userConst.interest.woman.value
                                ? "selected"
                                : ""
                            }`}
                            onClick={() =>
                              onInterestSelect(userConst.interest.woman.value)
                            }
                          >
                            {t("common.lesbian")}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>

        {gender && (
          <div className="bottom-container">
            <div
              className={`continue-button ${
                isMobile ? "full-width" : "partial-width"
              } ${isFormValid() ? "" : "disabled-button"}`}
              onClick={onNextPage}
            >
              {t("common.continue")}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Join;
