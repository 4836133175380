// General
import "./coins-expiry-banner.scss";
import { useEffect } from "react";
// Services
import { useLazyGetVirtualCurrencyCoinBalanceQuery } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateCoinExpiryInterval,
  updateCoinExpirySeconds,
  startCoinExpiryTimer,
} from "../../../../redux/store/navbarStore";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";

const CoinsExpiryBanner = () => {
  // API variables
  const [
    getVirtualCurrencyCoinBalance,
    {
      data: getVirtualCurrencyCoinBalanceData,
      error: getVirtualCurrencyCoinBalanceErrorData,
      isFetching: getVirtualCurrencyCoinBalanceFetching,
      isLoading: getVirtualCurrencyCoinBalanceLoading,
      isSuccess: getVirtualCurrencyCoinBalanceSuccess,
      isError: getVirtualCurrencyCoinBalanceError,
    },
  ] = useLazyGetVirtualCurrencyCoinBalanceQuery();

  // Redux variables
  const coinExpiryInterval = useSelector(
    (state) => state.navbar.coinExpiryInterval
  );
  const coinExpirySeconds = useSelector(
    (state) => state.navbar.coinExpirySeconds
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getVirtualCurrencyCoinBalance(null, true);
  }, []);

  // Lifecycle | Check for update | getVirtualCurrencyCoinBalance API Response
  useEffect(() => {
    if (
      getVirtualCurrencyCoinBalanceFetching ||
      getVirtualCurrencyCoinBalanceLoading
    ) {
    } else if (getVirtualCurrencyCoinBalanceSuccess) {
      if (getVirtualCurrencyCoinBalanceData?.data?.coins_expiry) {
        computeExpiryDate(
          getVirtualCurrencyCoinBalanceData?.data?.coins_expiry
            ?.expiry_date_epoch,
          getVirtualCurrencyCoinBalanceData?.data?.coins_expiry?.expiry_date
        );
      }
    } else if (getVirtualCurrencyCoinBalanceError) {
    }
  }, [
    getVirtualCurrencyCoinBalanceFetching,
    getVirtualCurrencyCoinBalanceLoading,
    getVirtualCurrencyCoinBalanceSuccess,
    getVirtualCurrencyCoinBalanceError,
  ]);

  // Event Handlers | Button
  const onNavigateToLive = () => {
    onNavigate(routeConst.live.path);
  };

  // Utility Functions
  const computeExpiryDate = (epoch, date) => {
    if (coinExpiryInterval) return;

    const currentDate = moment();
    const expiryDate = moment(date);
    const expiryDateEpoch = epoch;
    const diffInSeconds = expiryDate.diff(currentDate, "seconds");

    dispatch(updateCoinExpirySeconds(diffInSeconds));
    dispatch(
      updateCoinExpiryInterval(
        setInterval(() => {
          dispatch(startCoinExpiryTimer());
        }, 1000)
      )
    );
  };
  const getExpiringCopyWriting = (seconds) => {
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (seconds > 24 * 60 * 60) {
      return `${days} ${t("common.days")} ${hours
        .toString()
        .padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
    } else {
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
    }
  };

  if (
    getVirtualCurrencyCoinBalanceSuccess &&
    getVirtualCurrencyCoinBalanceData?.data?.coins_expiry
  ) {
    return (
      <div id="coins-expiry-banner-subcomponent">
        <Trans
          i18nKey={"common.wallet_expiry_description"}
          components={{
            br: <br />,
            span: <span className="yellow-expiry-text" />,
            trynow: <span className="underline" onClick={onNavigateToLive} />,
          }}
          values={{
            coins:
              getVirtualCurrencyCoinBalanceData?.data?.coins_expiry?.balance,
            countdown: getExpiringCopyWriting(coinExpirySeconds),
          }}
        />
      </div>
    );
  }
};

export default CoinsExpiryBanner;
