// General
import "./edit.scss";
import { useEffect, useRef } from "react";
// Services
import {
  spiApi,
  useLazyGetProfileQuery,
  useUpdateEditUserProfileMutation,
  useUpdateUserLocationMutation,
} from "../../../services/data.service";
// Static Data
import userConst from "../../../const/userConst";
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateGender,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateSpecialBadges,
  updateProfilePhoto,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateDatingStyles,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateAboutMeManualText,
  updateLookingFor,
  updateOccupation,
  updateLifestyle,
  updateDatingBudget,
  updateAnnualIncome,
  updateNetworth,
  updatePosition,
  updateIndustry,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateAlcoholPreference,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,

  // About Me Functions
  updatePersonalityType,
  updateHobbies,
  updateFitnessLevel,
  updateStarSign,
  updateDominantOrSubmissive,
  updatePersonalityTraits,

  // Looking For Functions
  updateLookingForAgeRange,
  updateLookingForLocation,
  updateLookingForEthnicityRace,

  // User Utility
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateIsSilentInteraction,
  updateRole,
  updateIsSelf,
} from "../../../redux/store/userCardStore";
import { updateEditProfileTab } from "../../../redux/store/userStore";
import {
  // Public Photos Functions
  updateProfilePhoto as updateEditProfilePhoto,
  updatePublicPhotos as updateEditPublicPhotos,

  // Private Photos Functions
  updatePrivatePhotos as updateEditPrivatePhotos,

  // Basic Information Functions
  updateEmail,
  updateUsername as updateEditUsername,
  updateAge as updateEditAge,
  updateBirthdate as updateEditBirthdate,

  // TOR Functions
  updateTor as updateEditTor,

  // Dating Styles Functions
  updateDatingStyles as updateEditDatingStyles,

  // Financial Information Functions
  updateLifestyle as updateEditLifestyle,
  updateDatingBudget as updateEditDatingBudget,
  updateNetworth as updateEditNetworth,
  updateAnnualIncome as updateEditAnnualIncome,

  // Locations Functions

  // Appearance Functions
  updateHeight as updateEditHeight,
  updateBodyType as updateEditBodyType,
  updateEthnicity as updateEditEthnicity,
  updateRace as updateEditRace,
  updateEyeColor as updateEditEyeColor,
  updateHairColor as updateEditHairColor,

  // Personal Information Functions
  updateOccupation as updateEditOccupation,
  updatePosition as updateEditPosition,
  updateIndustry as updateEditIndustry,
  updateEducation as updateEditEducation,
  updateRelationshipStatus as updateEditRelationshipStatus,
  updateChildren as updateEditChildren,
  updateSmoking as updateEditSmoking,
  updateDrinking as updateEditDrinking,
  updateAlcoholPreference as updateEditAlcoholPreference,

  // About Me Functions
  updateAboutMeManualTextStatus as updateEditAboutMeManualTextStatus,
  updateAboutMeManualText as updateEditAboutMeManualText,
  updateAboutMeManualTextNewContent as updateEditAboutMeManualTextNewContent,
  updateAboutMe as updateEditAboutMe,
  updateAboutMeList as updateEditAboutMeList,
  updateAboutMeLength as updateEditAboutMeLength,
  updatePersonalityTraits as updateEditPersonalityTraits,
  updatePersonalityType as updateEditPersonalityType,
  updateStarSign as updateEditStarSign,
  updateDominantOrSubmissive as updateEditDominantOrSubmissive,
  updateFitnessLevel as updateEditFitnessLevel,
  updateHobbies as updateEditHobbies,
  updateLookingFor as updateEditLookingFor,
  updateTagline as updateEditTagline,

  // Status Functions
  updateUsernameStatus,
  updateUsernameNewContent,

  // Location Functions
  updateLocation as updateEditLocation,
  updateLookingForAgeRange as updateEditLookingForAgeRange,
  updateLookingForLocation as updateEditLookingForLocation,
  updateLookingForEthnicityRace as updateEditLookingForEthnicityRace,
} from "../../../redux/store/editProfileStore";
import {
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../redux/store/profileStore";
import {
  resetDatingSearchLists,
  resetFilteredLists,
  resetRecentlyActiveLists,
  resetNearbyLists,
  resetNewestLists,
  resetCollegeLists,
  resetPremiumLists,

  // Pagination
  updateFilteredPagination,
  updateRecentlyActivePagination,
  updateNearbyPagination,
  updateNewestPagination,
  updateCollegePagination,
  updatePremiumPagination,
} from "../../../redux/store/searchStore";
import {
  updateUserProfileDialog,
  updateGeneralLoadingDialog,
} from "../../../redux/store/dialogStore";
import {
  updateSuccessToast,
  updateErrorToast,
} from "../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import {
  Tabs,
  Tab,
  Box,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import UtilityDesktopNavbar from "../../shared/elements/utility-desktop-navbar/utility-desktop-navbar";
import CustomAvatar from "../../shared/elements/custom-avatar/custom-avatar";
import EditProfile from "./edit-profile/edit-profile";
import Photos from "./photos/photos";
import Spinner from "../../shared/elements/spinner/spinner";
import DesktopEditProfile from "./desktop-edit-profile/desktop-edit-profile";
import ModerationStatus from "./moderation-status/moderation-status";
import VerifiedProfileBadge from "../../shared/elements/verified-profile-badge/verified-profile-badge";
import ProfileRejectedInfoDialog from "../../shared/dialog-content/profile-rejected-info-dialog/profile-rejected-info-dialog";

const ProfileEdit = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getProfileReference,
    {
      data: getProfileReferenceData,
      error: getProfileReferenceErrorData,
      isFetching: getProfileReferenceFetching,
      isLoading: getProfileReferenceLoading,
      isSuccess: getProfileReferenceSuccess,
      isError: getProfileReferenceError,
    },
  ] = useLazyGetProfileQuery();
  const [
    updateUserProfile,
    {
      data: updateUserProfileData,
      error: updateUserProfileErrorData,
      isLoading: updateUserProfileLoading,
      isSuccess: updateUserProfileSuccess,
      isError: updateUserProfileError,
    },
  ] = useUpdateEditUserProfileMutation();
  const [
    updateUserLocation,
    {
      data: updateUserLocationData,
      error: updateUserLocationErrorData,
      isLoading: updateUserLocationLoading,
      isSuccess: updateUserLocationSuccess,
      isError: updateUserLocationError,
    },
  ] = useUpdateUserLocationMutation();

  // General variables
  const isMounted = useRef(false);

  // Redux variables
  const profilePhoto = useSelector((state) => state.editProfile.profilePhoto);
  const username = useSelector((state) => state.editProfile.username);
  const usernameNewContent = useSelector(
    (state) => state.editProfile.usernameNewContent
  );
  const usernameStatus = useSelector(
    (state) => state.editProfile.usernameStatus
  );
  const age = useSelector((state) => state.editProfile.age);
  const birthdate = useSelector((state) => state.editProfile.birthdate);
  const datingStyles = useSelector((state) => state.editProfile.datingStyles);
  const location = useSelector((state) => state.editProfile.location);
  const aboutMe = useSelector((state) => state.editProfile.aboutMe);
  const aboutMeManualText = useSelector(
    (state) => state.editProfile.aboutMeManualText
  );
  const lookingFor = useSelector((state) => state.editProfile.lookingFor);
  const annualIncome = useSelector((state) => state.editProfile.annualIncome);
  const networth = useSelector((state) => state.editProfile.networth);
  const occupation = useSelector((state) => state.editProfile.occupation);
  const lifestyle = useSelector((state) => state.editProfile.lifestyle);
  const datingBudget = useSelector((state) => state.editProfile.datingBudget);
  const education = useSelector((state) => state.editProfile.education);
  const relationshipStatus = useSelector(
    (state) => state.editProfile.relationshipStatus
  );
  const smoking = useSelector((state) => state.editProfile.smoking);
  const drinking = useSelector((state) => state.editProfile.drinking);
  const alcoholPreference = useSelector(
    (state) => state.editProfile.alcoholPreference
  );
  const height = useSelector((state) => state.editProfile.height);
  const bodyType = useSelector((state) => state.editProfile.bodyType);
  const ethnicity = useSelector((state) => state.editProfile.ethnicity);
  const race = useSelector((state) => state.editProfile.race);
  const eyeColor = useSelector((state) => state.editProfile.eyeColor);
  const hairColor = useSelector((state) => state.editProfile.hairColor);
  const position = useSelector((state) => state.editProfile.position);
  const industry = useSelector((state) => state.editProfile.industry);
  const children = useSelector((state) => state.editProfile.children);
  const latitude = useSelector((state) => state.user.latitude);
  const longitude = useSelector((state) => state.user.longitude);
  const editLocation = useSelector((state) => state.editProfile.location);
  const personalityTraits = useSelector(
    (state) => state.editProfile.personalityTraits
  );
  const personalityType = useSelector(
    (state) => state.editProfile.personalityType
  );
  const starSign = useSelector((state) => state.editProfile.starSign);
  const dominantOrSubmissive = useSelector(
    (state) => state.editProfile.dominantOrSubmissive
  );
  const fitnessLevel = useSelector((state) => state.editProfile.fitnessLevel);
  const hobbies = useSelector((state) => state.editProfile.hobbies);

  const lookingForAgeRange = useSelector(
    (state) => state.editProfile.lookingForAgeRange
  );
  const lookingForLocation = useSelector(
    (state) => state.editProfile.lookingForLocation
  );
  const lookingForEthnicityRace = useSelector(
    (state) => state.editProfile.lookingForEthnicityRace
  );
  const editProfilePassthrough = useSelector(
    (state) => state.user.editProfilePassthrough
  );
  const editProfileTab = useSelector((state) => state.user.editProfileTab);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // MUI variables
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t, i18n } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-25-Page",
      },
    });

    getProfile(null, true);
    getProfileReference("?force_update=1", false);
  }, []);

  // Lifecycle | Check for update | Get Profile Reference API Response
  useEffect(() => {
    if (getProfileReferenceFetching || getProfileReferenceLoading) {
    } else if (getProfileReferenceSuccess) {
      if (getProfileReferenceData?.status === 0) {
        dispatch(
          updateProfileCompletionPercentage(
            getProfileReferenceData?.data?.profile_progress?.summary?.progress
          )
        );
        dispatch(
          updateProfileRequirementPopup(
            getProfileReferenceData?.data?.profile_progress?.summary
              ?.requirement
          )
        );

        // Public Photos
        dispatch(
          updateEditProfilePhoto(getProfileReferenceData?.data?.profile_photo)
        );
        dispatch(
          updateEditPublicPhotos(
            getProfileReferenceData?.data?.public_photos?.filter(
              (photo) =>
                photo?.id !== getProfileReferenceData?.data?.profile_photo?.id
            )
          )
        );

        // Private Photos
        dispatch(
          updateEditPrivatePhotos(getProfileReferenceData?.data?.private_photos)
        );

        // Basic Information
        dispatch(updateEmail(getProfileReferenceData?.data?.email));
        dispatch(updateEditUsername(getProfileReferenceData?.data?.username));
        dispatch(
          updateEditAge(
            getProfileReferenceData?.data?.appearance?.age > 100
              ? null
              : getProfileReferenceData?.data?.appearance?.age
          )
        );
        dispatch(
          updateEditBirthdate(
            getProfileReferenceData?.data?.appearance?.birth_date ===
              "0000-00-00"
              ? null
              : getProfileReferenceData?.data?.appearance?.birth_date
          )
        );

        // TOR
        dispatch(updateEditTor(getProfileReferenceData?.data?.tor_tags));

        // Dating Style
        dispatch(
          updateEditDatingStyles(
            getProfileReferenceData?.data?.relationship?.dating_style
          )
        );

        // Financial Information
        dispatch(
          updateEditLifestyle(
            getProfileReferenceData?.data?.occupation?.lifestyle_budget
          )
        );
        dispatch(
          updateEditDatingBudget(
            getProfileReferenceData?.data?.relationship?.dating_budget
          )
        );
        dispatch(
          updateEditAnnualIncome(
            getProfileReferenceData?.data?.occupation?.annual_income
          )
        );
        dispatch(
          updateEditNetworth(
            getProfileReferenceData?.data?.occupation?.networth
          )
        );

        // Location

        // Appearance
        dispatch(
          updateEditHeight(getProfileReferenceData?.data?.appearance?.height)
        );
        dispatch(
          updateEditBodyType(
            getProfileReferenceData?.data?.appearance?.body_type
          )
        );
        dispatch(
          updateEditEthnicity(
            getProfileReferenceData?.data?.appearance?.ethnicity
          )
        );
        dispatch(
          updateEditRace(getProfileReferenceData?.data?.appearance?.race)
        );
        dispatch(
          updateEditEyeColor(
            getProfileReferenceData?.data?.appearance?.eye_color
          )
        );
        dispatch(
          updateEditHairColor(
            getProfileReferenceData?.data?.appearance?.hair_color
          )
        );

        // Personal Information
        dispatch(
          updateEditOccupation(
            getProfileReferenceData?.data?.occupation?.occupation
          )
        );
        dispatch(
          updateEditPosition(
            getProfileReferenceData?.data?.occupation?.position
          )
        );
        dispatch(
          updateEditIndustry(
            getProfileReferenceData?.data?.occupation?.industry
          )
        );
        dispatch(
          updateEditEducation(
            getProfileReferenceData?.data?.occupation?.education
          )
        );
        dispatch(
          updateEditRelationshipStatus(
            getProfileReferenceData?.data?.relationship?.relationship_status
          )
        );
        dispatch(
          updateEditChildren(
            getProfileReferenceData?.data?.relationship?.children
          )
        );
        dispatch(
          updateEditSmoking(getProfileReferenceData?.data?.habit?.smoking)
        );
        dispatch(
          updateEditDrinking(getProfileReferenceData?.data?.habit?.drinking)
        );
        dispatch(
          updateEditAlcoholPreference(
            getProfileReferenceData?.data?.habit?.alcohol
          )
        );

        // About Me Manual Text
        dispatch(
          updateEditAboutMeManualTextStatus(
            getProfileReferenceData?.data?.profile_details_approval?.aboutme
              ?.approval
          )
        );
        dispatch(
          updateEditAboutMeManualText(
            getProfileReferenceData?.data?.aboutme || ""
          )
        );
        dispatch(
          updateEditAboutMeManualTextNewContent(
            getProfileReferenceData?.data?.profile_details_approval?.aboutme
              ?.new_content
          )
        );

        // About Me
        const personalityType =
          getProfileReferenceData?.data?.personality?.personality_type;
        const hobbies = getProfileReferenceData?.data?.habit?.hobbies;
        const fitnessLevel =
          getProfileReferenceData?.data?.habit?.fitness_level;
        const starSign = getProfileReferenceData?.data?.personality?.star_sign;
        const dominantOrSubmissive =
          getProfileReferenceData?.data?.personality?.dominant_or_submissive;
        const personalityTraits =
          getProfileReferenceData?.data?.personality?.personality_traits;

        dispatch(updateEditPersonalityTraits(personalityTraits));
        dispatch(updateEditPersonalityType(personalityType));
        dispatch(updateEditStarSign(starSign));
        dispatch(updateEditDominantOrSubmissive(dominantOrSubmissive));
        dispatch(updateEditFitnessLevel(fitnessLevel));
        dispatch(updateEditHobbies(hobbies));

        let tempAboutMeList = [];
        let tempAboutMeLength = 0;

        tempAboutMeList?.push({
          name: "personality_type",
          data: personalityType?.length > 0 ? personalityType : null,
        });

        if (personalityType?.length > 0) {
          tempAboutMeLength++;
        }

        tempAboutMeList.push({
          name: "hobbies",
          data: hobbies?.length > 0 ? hobbies : null,
        });

        if (hobbies?.length > 0) {
          tempAboutMeLength++;
        }

        tempAboutMeList?.push({
          name: "star_sign",
          data: starSign,
        });

        if (starSign) {
          tempAboutMeLength++;
        }

        tempAboutMeList?.push({
          name: "fitness_level",
          data: fitnessLevel,
        });

        if (fitnessLevel) {
          tempAboutMeLength++;
        }

        tempAboutMeList?.push({
          name: "dominant_or_submissive",
          data: dominantOrSubmissive,
        });

        if (dominantOrSubmissive) {
          tempAboutMeLength++;
        }

        tempAboutMeList?.push({
          name: "personality_traits",
          data: personalityTraits,
        });

        if (personalityTraits) {
          tempAboutMeLength++;
        }

        dispatch(updateEditAboutMeList(tempAboutMeList));
        dispatch(updateEditAboutMeLength(tempAboutMeLength));

        dispatch(updateEditAboutMe(getProfileReferenceData?.data?.aboutme));

        // Looking For
        dispatch(
          updateEditLookingForAgeRange({
            min: getProfileReferenceData?.data?.looking_fors?.age?.min,
            max: getProfileReferenceData?.data?.looking_fors?.age?.max,
          })
        );
        dispatch(
          updateEditLookingForLocation(
            getProfileReferenceData?.data?.looking_fors?.locations
          )
        );
        const temp =
          getProfileReferenceData?.data?.looking_fors?.ethnicity?.map(
            (item) => ({
              ethnicity: {
                id: item?.option?.id,
                locale: item?.option?.locale,
                display_locale: item?.option?.locale,
              },
              race: item?.sub_option
                ? {
                    id: item?.sub_option?.id,
                    locale: item?.sub_option?.locale,
                    display_locale: item?.sub_option?.locale,
                  }
                : null,
            })
          );
        dispatch(updateEditLookingForEthnicityRace(temp));

        dispatch(
          updateEditLookingFor(getProfileReferenceData?.data?.lookingfor)
        );
        dispatch(updateEditTagline(getProfileReferenceData?.data?.tagline));

        // Status Functions
        dispatch(
          updateUsernameStatus(
            getProfileReferenceData?.data?.profile_details_approval?.username
              ?.approval
          )
        );
        dispatch(
          updateUsernameNewContent(
            getProfileReferenceData?.data?.profile_details_approval?.username
              ?.new_content
          )
        );

        const locationObj = {
          lat: getProfileReferenceData?.data?.location?.latitude,
          lng: getProfileReferenceData?.data?.location?.longitude,
          location_data: {
            city: getProfileReferenceData?.data?.location?.city,
            state: getProfileReferenceData?.data?.location?.state,
            formatted_address:
              getProfileReferenceData?.data?.location?.full_address || "",
            country: getProfileReferenceData?.data?.location?.country,
          },
        };

        dispatch(updateEditLocation(locationObj));
      }
    } else if (getProfileReferenceError) {
    }
  }, [
    getProfileReferenceFetching,
    getProfileReferenceLoading,
    getProfileReferenceSuccess,
    getProfileReferenceError,
  ]);

  // Lifecycle | Check for update | Update User Profile API Response
  useEffect(() => {
    if (updateUserProfileLoading) {
      dispatch(updateGeneralLoadingDialog(true));
    } else if (updateUserProfileSuccess) {
      dispatch(updateGeneralLoadingDialog(false));

      // Backend returning empty if success
      const toastObj = {
        message: t("signup.profile_updated"),
        autoClose: 3000,
      };
      dispatch(updateSuccessToast(toastObj));

      // Reset Search Page
      dispatch(resetDatingSearchLists());
      dispatch(resetFilteredLists());
      dispatch(resetRecentlyActiveLists());
      dispatch(resetNearbyLists());
      dispatch(resetNewestLists());
      dispatch(resetCollegeLists());
      dispatch(resetPremiumLists());
      dispatch(updateFilteredPagination(null));
      dispatch(updateRecentlyActivePagination(null));
      dispatch(updateNearbyPagination(null));
      dispatch(updateNewestPagination(null));
      dispatch(updateCollegePagination(null));
      dispatch(updatePremiumPagination(null));

      // Recall GET User Profile API
      dispatch({
        type: `${spiApi.reducerPath}/invalidateTags`,
        payload: ["OwnProfile"],
      });

      onNavigate(routeConst.profile.overview.path);
    } else if (updateUserProfileError) {
      dispatch(updateGeneralLoadingDialog(false));

      const errorToastObj = {
        message: updateUserProfileErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(errorToastObj));
    }
  }, [
    updateUserProfileLoading,
    updateUserProfileSuccess,
    updateUserProfileError,
  ]);

  // Event Handlers | Button
  const onViewOwnProfile = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-25-25.1-Button",
      },
    });

    // User View
    dispatch(updateId(getProfileData?.data?.id));
    dispatch(updateIdInt(getProfileData?.data?.id_int));
    dispatch(updateUsername(usernameNewContent || username));
    dispatch(updateAge(age));
    dispatch(updateGender(getProfileData?.data?.gender));
    dispatch(updateLocation(getProfileData?.data?.location?.city));
    dispatch(updateMembershipType(getProfileData?.data?.membership_type));
    dispatch(updateVerifiedProfile(getProfileData?.data?.is_verified_profile));
    dispatch(updateVerifiedCaller(getProfileData?.data?.verified_caller));
    dispatch(
      updateLevellingBadge(getProfileData?.data?.levelling_profile?.badge)
    );
    dispatch(
      updateLivestreamingAchievementBadge(
        getProfileData?.data?.live_streaming_achievements?.chat_badges_set
      )
    );
    dispatch(updateSpecialBadges(getProfileData?.data?.special_badges));
    dispatch(
      updateProfilePhoto(
        getProfileData?.data?.profile_photo?.photo_status === "APPROVED"
          ? getProfileData?.data?.profile_photo?.original_photo
          : null
      )
    );
    dispatch(
      updatePublicPhotos(
        getProfileData?.data?.public_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
            status: photo?.photo_status,
          };
        })
      )
    );
    dispatch(
      updatePrivatePhotos(
        getProfileData?.data?.private_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
            status: photo?.photo_status,
          };
        })
      )
    );
    dispatch(updateIsSilentInteraction(true));
    dispatch(updatePrivatePhotoRequested(true));
    dispatch(updatePrivatePhotoApproval(true));
    dispatch(updateTorTags(getProfileData?.data?.tor_tags));
    dispatch(updateDatingStyles(datingStyles));
    dispatch(
      updateProfileAchievementBadge(
        getProfileData?.data?.live_streaming_achievements?.profile_badges
      )
    );
    // dispatch(updateAboutMe(aboutMeNewContent || aboutMe));
    dispatch(updateLookingFor(lookingFor));
    dispatch(updatePosition(position));
    dispatch(updateIndustry(industry));
    dispatch(updateOccupation(occupation));
    dispatch(updateLifestyle(lifestyle));
    dispatch(updateDatingBudget(datingBudget));
    dispatch(updateAnnualIncome(annualIncome));
    dispatch(updateNetworth(networth));
    dispatch(updateEducation(education));
    dispatch(updateRelationship(relationshipStatus));
    dispatch(updateSmoking(smoking));
    dispatch(updateDrinking(drinking));
    dispatch(updateAlcoholPreference(alcoholPreference));
    dispatch(updateHeight(height));
    dispatch(updateBodyType(bodyType));
    dispatch(updateEthnicity(ethnicity));
    dispatch(updateEyeColor(eyeColor));
    dispatch(updateHairColor(hairColor));
    dispatch(updateChildren(children));

    const personalityType = getProfileData?.data?.personality?.personality_type;
    const hobbies = getProfileData?.data?.habit?.hobbies;
    const fitnessLevel = getProfileData?.data?.habit?.fitness_level;
    const starSign = getProfileData?.data?.personality?.star_sign;
    const dominantOrSubmissive =
      getProfileData?.data?.personality?.dominant_or_submissive;
    const personalityTraits =
      getProfileData?.data?.personality?.personality_traits;

    let tempAboutMeList = [];

    tempAboutMeList?.push({
      name: "personality_type",
      data: personalityType?.length > 0 ? personalityType : null,
    });
    tempAboutMeList?.push({
      name: "hobbies",
      data: hobbies?.length > 0 ? hobbies : null,
    });
    tempAboutMeList?.push({
      name: "star_sign",
      data: starSign,
    });
    tempAboutMeList?.push({
      name: "fitness_level",
      data: fitnessLevel,
    });
    tempAboutMeList?.push({
      name: "dominant_or_submissive",
      data: dominantOrSubmissive,
    });
    tempAboutMeList?.push({
      name: "personality_traits",
      data: personalityTraits,
    });
    dispatch(updatePersonalityType(personalityType));
    dispatch(updateHobbies(hobbies));
    dispatch(updateFitnessLevel(fitnessLevel));
    dispatch(updateStarSign(starSign));
    dispatch(updateDominantOrSubmissive(dominantOrSubmissive));
    dispatch(updatePersonalityTraits(personalityTraits));

    dispatch(updateAboutMe(tempAboutMeList));

    dispatch(updateAboutMeManualText(aboutMeManualText));

    dispatch(updateLookingForAgeRange(lookingForAgeRange));
    dispatch(updateLookingForLocation(lookingForLocation));
    dispatch(updateLookingForEthnicityRace(lookingForEthnicityRace));

    // User Utility
    dispatch(updateRole(getProfileData?.data?.role));
    dispatch(updateLookingFor(getProfileData?.data?.interest));
    dispatch(updateIsSelf(true));

    dispatch(updateUserProfileDialog(true));
  };

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-25-25.2-Button-${
          newValue === 0 ? "EditProfileTab" : "PhotosTab"
        }`,
      },
    });

    // dispatch(updateEditProfileTab(newValue));
  };
  const onTabChangeBySwipe = (index) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-25-25.3-Button-${
          index === 0 ? "EditProfileTab" : "PhotosTab"
        }`,
      },
    });

    // dispatch(updateEditProfileTab(index));
  };

  if (getProfileReferenceFetching || getProfileReferenceLoading) {
    return <Spinner />;
  } else if (getProfileReferenceSuccess) {
    if (isMobile) {
      return (
        <div id="profile-edit-page">
          <div className="max-width-container">
            <UtilityDesktopNavbar
              leftButton={"back"}
              rightButton={"save-edit-profile"}
            />

            {/* In Review */}
            <ModerationStatus
              status={userConst.profileGeneralApprovalStatus.inReview}
            />

            {/* Rejected */}
            <ModerationStatus
              status={userConst.profileGeneralApprovalStatus.rejected}
            />

            <div className="padding-container">
              <div className="top-container">
                <div className="edit-label">{t("profile.edit")}</div>
                <div className="view-profile-label" onClick={onViewOwnProfile}>
                  {t("profile.view_profile_alt_2")}
                </div>
              </div>

              <EditProfile />
            </div>
          </div>

          <ProfileRejectedInfoDialog />
        </div>
      );
    } else {
      return <DesktopEditProfile />;
    }
  }
};

export default ProfileEdit;

// Material UI | Tab Functions
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }} className="tab-box-container">
          {children}
          {/* <Typography component={"div"}>{children}</Typography> */}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
