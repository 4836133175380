// General
import "./index.scss";
import React from "react";
import ReactDOM from "react-dom/client";
// Environment
import environment from "./environment/environment";
import router from "./router";
// Static Data
import { APP_IDENTIFIER } from "./const/apiConst";
// React Router DOM
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  ScrollRestoration,
  RouterProvider,
} from "react-router-dom";
// Service Worker
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// Redux
import { Provider } from "react-redux";
import store from "./redux/store";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// Material UI
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { isIOS } from "react-device-detect";
// i18next
import "./i18n"; // import i18n (needs to be bundled ;))
// Swiper
import { register } from "swiper/element/bundle";
// Web Vitals
import reportWebVitals from "./reportWebVitals";

// Initialize Sentry
Sentry.init({
  dsn: process.env["REACT_APP_SENTRY_DSN"],

  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),

    new Sentry.Replay(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.1,
  normalizeDepth: 10,
  release: APP_IDENTIFIER + "@" + environment.appVersion,
  environment: process.env["REACT_APP_ENVIRONMENT"],
  ignoreErrors: [
    "Message from unknown origin",
    "smartech is not defined",
    "Insider is not defined",
    "Can't find variable: Insider",
    "This browser doesn't support the API's required to use the Firebase SDK",
  ],

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0, //0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0, //1.0,
});

// Initialize react-gtm-module
TagManager.initialize({ gtmId: process.env["REACT_APP_GTM_ID"] });

// Material UI | Global Theme
const theme = createTheme({
  typography: {
    fontFamily: "Lato",
  },
});

// Initialize Swiper
register();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router}>
        <ScrollRestoration
          getKey={(location, matches) => {
            // default behavior
            return location.pathname;
          }}
        />
      </RouterProvider>
    </ThemeProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (
  isIOS &&
  (window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone === true)
) {
  serviceWorkerRegistration.register();
} else {
  serviceWorkerRegistration.unregister();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
