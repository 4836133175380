// General
import "./default-bottom-navbar.scss";
import { useState, useEffect } from "react";
// Static Data
import routeConst from "../../../../../const/routeConst";
// Material UI
import { Badge } from "@mui/material";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";

const DefaultBottomNavbar = (props) => {
  const {
    showSearch = true,
    showInbox = false,
    showVideoCall = false,
    showLive = false,
    showInterest = true,
    showProfile = true,
    // Badge Count
    inboxBadgeCount,
    interestBadgeCount,
    // Selected Icon
    currentRoute,
    // Callback Functions
    onNavigateClickCallback,
  } = props;

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Render variables
  let [homeIcon, setHomeIcon] = useState(getIcon("homeIcon", "home-icon"));
  let [messagingIcon, setMessagingIcon] = useState(
    getIcon("messagingIcon", "inbox-icon")
  );
  let [videoIcon, setVideoIcon] = useState(getIcon("videoIcon", "video-icon"));
  let [liveIcon, setLiveIcon] = useState(getIcon("liveIcon", "live-icon"));
  let [interestIcon, setInterestIcon] = useState(
    getIcon("favoriteIcon", "favorite-icon")
  );
  let [profileIcon, setProfileIcon] = useState(
    getIcon("profileIcon", "profile-icon")
  );

  // Lifecycle | Check for update | Change navbar icon based on route
  useEffect(() => {
    // Default Bottom Navbar Icon
    setHomeIcon(getIcon("homeIcon", "home-icon"));
    setMessagingIcon(getIcon("messagingIcon", "inbox-icon"));
    setVideoIcon(getIcon("videoIcon", "video-icon"));
    setLiveIcon(getIcon("liveIcon", "live-icon"));
    setInterestIcon(getIcon("favoriteIcon", "interest-icon"));
    setProfileIcon(getIcon("profileIcon", "profile-icon"));

    // Change Bottom Navbar Icon
    switch (currentRoute) {
      case routeConst.search.path:
        setHomeIcon(getIcon("homeIconSelected", "home-icon"));
        break;
      case routeConst.inbox.path:
      case routeConst.inbox.messaging.path:
      case routeConst.inbox.messaging.chat.path:
        setMessagingIcon(getIcon("messagingIconSelected", "inbox-icon"));
        break;
      case routeConst.videoCall.path:
        setVideoIcon(getIcon("videoIconSelected", "video-icon"));
        break;
      case routeConst.live.path:
        setLiveIcon(getIcon("liveIconSelected", "live-icon"));
        break;
      case routeConst.interest.path:
        setInterestIcon(getIcon("favoriteIconSelected", "interest-icon"));
        break;
      case routeConst.profile.overview.path:
        setProfileIcon(getIcon("profileIconSelected", "profile-icon"));
        break;
      default:
        break;
    }
  }, [currentRoute]);

  // Event Handlers | Button
  const onNavigateClick = (path) => {
    props.onNavigateClickCallback(path);
  };

  return (
    <div id="default-bottom-navbar">
      <div className="icons-container">
        {showSearch && (
          <div className="icon-container home-icon">
            <Badge className="custom-badge" badgeContent={0} variant="dot">
              <div
                className="link"
                onClick={() => onNavigateClick(routeConst.search.path)}
              >
                {homeIcon}
              </div>
            </Badge>
          </div>
        )}

        {showInbox && (
          <div className="icon-container inbox-icon">
            <Badge
              className="custom-badge custom-badge-position"
              badgeContent={inboxBadgeCount}
              variant="dot"
            >
              <div
                className="link"
                onClick={() => onNavigateClick(routeConst.inbox.path)}
              >
                {messagingIcon}
              </div>
            </Badge>
          </div>
        )}

        {showVideoCall && (
          <div className="icon-container video-icon">
            <Badge className="custom-badge" badgeContent={0} variant="dot">
              <div
                className="link"
                onClick={() => onNavigateClick(routeConst.videoCall.path)}
              >
                {videoIcon}
              </div>
            </Badge>
          </div>
        )}

        {showLive && (
          <div className="icon-container live-icon">
            <Badge className="custom-badge" badgeContent={0} variant="dot">
              <div
                className="link"
                onClick={() => onNavigateClick(routeConst.live.path)}
              >
                {liveIcon}
              </div>
            </Badge>
          </div>
        )}

        {showInterest && (
          <div className="icon-container interest-icon">
            <Badge
              className="interest-badge"
              badgeContent={interestBadgeCount ? interestBadgeCount : 0}
            >
              <div
                className="link"
                onClick={() => onNavigateClick(routeConst.interest.path)}
              >
                {interestIcon}
              </div>
            </Badge>
          </div>
        )}

        {showProfile && (
          <div className="icon-container profile-icon">
            <Badge className="custom-badge" badgeContent={0} variant="dot">
              <div
                className="link"
                onClick={() =>
                  onNavigateClick(routeConst.profile.overview.path)
                }
              >
                {profileIcon}
              </div>
            </Badge>
          </div>
        )}
      </div>
    </div>
  );
};

export default DefaultBottomNavbar;
