// General
import "./wallet.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetCardDetailsQuery,
  useLazyGetVirtualCurrencyCoinBalanceQuery,
} from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateCardDetail } from "../../../redux/store/paymentStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Divider } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../shared/elements/spinner/spinner";
import CoinsExpiryBanner from "./coins-expiry-banner/coins-expiry-banner";
import BuyCoins from "./buy-coins/buy-coins";

const ProfileWallet = () => {
  // API variables
  const [
    getCardDetails,
    {
      data: getCardDetailsData,
      error: getCardDetailsErrorData,
      isFetching: getCardDetailsFetching,
      isLoading: getCardDetailsLoading,
      isSuccess: getCardDetailsSuccess,
      isError: getCardDetailsError,
    },
  ] = useLazyGetCardDetailsQuery();
  const [
    getCoins,
    {
      data: getCoinsData,
      error: getCoinsErrorData,
      isFetching: getCoinsFetching,
      isLoading: getCoinsLoading,
      isSuccess: getCoinsSuccess,
      isError: getCoinsError,
    },
  ] = useLazyGetVirtualCurrencyCoinBalanceQuery();

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-24",
      },
    });

    getCardDetails();
    getCoins(null, true);
  }, [isLoggedIn]);

  // Lifecycle | Check for update | Card Details API Response
  useEffect(() => {
    if (getCardDetailsFetching || getCardDetailsLoading) {
    } else if (getCardDetailsSuccess) {
      if (getCardDetailsData?.status === 0) {
        if (getCardDetailsData?.data?.length > 0) {
          dispatch(updateCardDetail(getCardDetailsData.data));
        }
      } else {
      }
    } else if (getCardDetailsError) {
      if (getCardDetailsErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getCardDetailsFetching,
    getCardDetailsLoading,
    getCardDetailsSuccess,
    getCardDetailsError,
  ]);

  // Lifecycle | Check for update | Coins API Response
  useEffect(() => {
    if (getCoinsFetching || getCoinsLoading) {
    } else if (getCoinsSuccess) {
    } else if (getCoinsError) {
      if (getCoinsErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [getCoinsFetching, getCoinsLoading, getCoinsSuccess, getCoinsError]);

  return (
    <div id="profile-wallet-page">
      <div className="max-width-container">
        {getCoinsSuccess && <CoinsExpiryBanner />}

        <div className="coins-balance-container">
          <div className="coin-image-container">
            {getIcon("sbCoinIcon", "coin-image")}
          </div>

          <div className="coin-balance-label">{t("profile.coins_balance")}</div>
          {getCoinsFetching || getCoinsLoading ? (
            <Spinner isPadding={false} />
          ) : (
            <div className="coin-balance">
              {getCoinsData?.data?.balance?.toLocaleString()}
            </div>
          )}
        </div>

        <Divider />

        <BuyCoins />
      </div>
    </div>
  );
};

export default ProfileWallet;
