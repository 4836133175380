// General
import "./user-profile-dialog.scss";
import { useState, useEffect, forwardRef } from "react";
import { useSearchParams } from "react-router-dom";
// Services
import {
  useLazyGetUserAioQuery,
  useLazyGetUserProfileQuery,
  useLazyGetSearchUserProfileQuery,
} from "../../../../services/data.service";
// Static Data
import userConst from "../../../../const/userConst";
import userCardConst from "../../../../const/userCardConst";
import profileConst from "../../../../const/profileConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // User View
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateGender,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateSpecialBadges,
  updateProfilePhoto,
  updateProfileDecorations,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateDatingStyles,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateAboutMeManualText,
  updateAboutMeReadStatus,
  updateLookingFor,
  updateTagline, // Deprecated
  updateAnnualIncome,
  updateNetworth,
  updateOccupation, // Deprecated
  updatePosition,
  updateIndustry,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateAlcoholPreference,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  updateShowLastActive,
  updateLastActive,
  updateShowMemberSince,
  updateMemberSince,
  updateIsLgbtqInterested,

  // About Me Functions
  updatePersonalityType,
  updateHobbies,
  updateFitnessLevel,
  updateStarSign,
  updateDominantOrSubmissive,
  updatePersonalityTraits,

  // Looking For Functions
  updateLookingForAgeRange,
  updateLookingForLocation,
  updateLookingForEthnicityRace,

  // User Utility
  updateProfileStatus,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsOnline,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
  updateRole,
  updateIsSelf,
} from "../../../../redux/store/userCardStore";
import { updateUserIsLiveData } from "../../../../redux/store/livestreamingStore";
import {
  updateUserProfileDialog,
  updateDialogSearchParam,
  resetDialogSearchParam,
  updateUserIsLiveDialog,
} from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Material UI
import {
  Tabs,
  Tab,
  Divider,
  Dialog,
  Slide,
  useMediaQuery,
} from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import useGetUserProfileStatus from "../../../utility/custom-hooks/useGetUserProfileStatus-hook";
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";
// Components
import Spinner from "../../elements/spinner/spinner";
import ComponentNavbar from "../../elements/component-navbar/component-navbar";
import TopUserContainer from "../../../profile/view/top-user-container/top-user-container";
import BottomUserContainer from "../../../profile/view/bottom-user-container/bottom-user-container";
import PhotoInfo from "../../elements/profile/photo-info/photo-info";
import BasicInfo from "../../elements/profile/basic-info/basic-info";
import TermsOfRelationship from "../../elements/profile/terms-of-relationship/terms-of-relationship";
import ProfileAboutMe from "../../elements/profile/profile-about-me/profile-about-me";
import ProfileLookingFor from "../../elements/profile/profile-looking-for/profile-looking-for";
import ProfileDatingStyle from "../../elements/profile/profile-dating-styles/profile-dating-styles";
import ProfileFinancialInformation from "../../elements/profile/profile-financial-information/profile-financial-information";
import ProfileAlcoholPreference from "../../elements/profile/profile-alcohol-preference/profile-alcohol-preference";
import ProfileMoreAboutMe from "../../elements/profile/profile-more-about-me/profile-more-about-me";
import ProfileAchievements from "../../elements/profile/achievements-info/achievements-info";
import ProfileActionButtons from "../../elements/profile/profile-action-buttons/profile-action-buttons";

const UserProfileDialog = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    getUserProfile,
    {
      data: getUserProfileData,
      error: getUserProfileErrorData,
      isFetching: getUserProfileFetching,
      isLoading: getUserProfileLoading,
      isSuccess: getUserProfileSuccess,
      isError: getUserProfileError,
    },
  ] = useLazyGetUserProfileQuery();
  const [
    getSearchUserProfile,
    {
      data: getSearchUserProfileData,
      error: getSearchUserProfileErrorData,
      isFetching: getSearchUserProfileFetching,
      isLoading: getSearchUserProfileLoading,
      isSuccess: getSearchUserProfileSuccess,
      isError: getSearchUserProfileError,
    },
  ] = useLazyGetSearchUserProfileQuery();

  // General variables
  const [profileTab, setProfileTab] = useState(0);

  // Redux variables
  const userProfileDialog = useSelector(
    (state) => state.dialog.userProfileDialog
  );
  const id = useSelector((state) => state.userCard.id);
  const idInt = useSelector((state) => state.userCard.idInt);
  const torTags = useSelector((state) => state.userCard.torTags);
  const shouldCallApi = useSelector((state) => state.userCard.shouldCallApi);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
  });

  // Router variables
  const [searchParams, setSearchParams] = useSearchParams();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getProfileStatus = useGetUserProfileStatus();
  const getAsset = AssetManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!userProfileDialog || !id || !shouldCallApi) return;

    getUserProfile(id);
    // getSearchUserProfile({ user_id: idInt }); // Deprecated
  }, [userProfileDialog, shouldCallApi]);

  // Lifecycle | Mounted
  useEffect(() => {
    if (userProfileDialog) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-UserProfile-Dialog",
        },
      });
      getUserAio(null, true);
      // Testing
      // dispatch(
      //   updateDialogSearchParam({
      //     key: "userProfileDialog=true",
      //     value: true,
      //   })
      // );
    } else {
      // Testing
      // dispatch(
      //   updateDialogSearchParam({
      //     key: "userProfileDialog=true",
      //     value: false,
      //   })
      // );

      dispatch(updateIsSelf(false));
    }
  }, [userProfileDialog]);

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(profileTab);

    emblaApi?.on("select", () => {
      setProfileTab(emblaApi?.selectedScrollSnap());
    });
  }, [emblaApi]);

  // Lifecycle | Check for update | Get User Profile API Response
  useEffect(() => {
    if (getUserProfileFetching || getUserProfileLoading) {
    } else if (getUserProfileSuccess) {
      switch (getUserProfileData?.status) {
        case 0:
        case 1:
          dispatch(updateId(getUserProfileData?.data?.id));
          dispatch(updateIdInt(getUserProfileData?.data?.id_int));
          dispatch(updateUsername(getUserProfileData?.data?.username));
          dispatch(updateAge(getUserProfileData?.data?.appearance?.age));
          dispatch(updateGender(getUserProfileData?.data?.gender));
          dispatch(updateLocation(getUserProfileData?.data?.location?.city));
          dispatch(
            updateMembershipType(getUserProfileData?.data?.membership_type)
          );
          dispatch(
            updateVerifiedProfile(getUserProfileData?.data?.is_verified_profile)
          );
          dispatch(
            updateVerifiedCaller(getUserProfileData?.data?.verified_caller)
          );
          dispatch(
            updateLevellingBadge(
              getUserProfileData?.data?.levelling_profile?.badge
            )
          );
          dispatch(
            updateLivestreamingAchievementBadge(
              getUserProfileData?.data?.live_streaming_achievements
                ?.chat_badges_set
            )
          );
          dispatch(
            updateSpecialBadges(getUserProfileData?.data?.special_badges)
          );
          dispatch(
            updateProfilePhoto(
              getUserProfileData?.data?.profile_photo?.photo_status ===
                "REJECTED"
                ? null
                : getUserProfileData?.data?.profile_photo?.original_photo
            )
          );
          dispatch(
            updatePublicPhotos(
              getUserProfileData?.data?.public_photos?.map((photo) => {
                return {
                  photoUrl: photo?.original_photo,
                };
              })
            )
          );
          dispatch(
            updatePrivatePhotos(
              getUserProfileData?.data?.private_photos?.map((photo) => {
                return {
                  photoUrl: photo?.original_photo,
                  thumbnailData: photo?.thumbnail_data,
                  isSensitive: photo?.is_sensitive,
                };
              })
            )
          );
          dispatch(updateTorTags(getUserProfileData?.data?.tor_tags));
          dispatch(
            updateDatingStyles(
              getUserProfileData?.data?.relationship?.dating_style
            )
          );
          dispatch(
            updateProfileAchievementBadge(
              getUserProfileData?.data?.live_streaming_achievements
                ?.profile_badges
            )
          );
          dispatch(updateAboutMeManualText(getUserProfileData?.data?.aboutme));
          dispatch(
            updateAboutMeReadStatus(
              getUserProfileData?.data?.aboutme_read_status
            )
          );
          dispatch(updateLookingFor(getUserProfileData?.data?.interest));
          dispatch(
            updateAnnualIncome(
              getUserProfileData?.data?.occupation?.annual_income
            )
          );
          dispatch(
            updateNetworth(getUserProfileData?.data?.occupation?.net_worth)
          );
          dispatch(
            updatePosition(getUserProfileData?.data?.occupation?.position)
          );
          dispatch(
            updateIndustry(getUserProfileData?.data?.occupation?.industry)
          );
          dispatch(
            updateLifestyle(
              getUserProfileData?.data?.occupation?.lifestyle_budget
            )
          );
          dispatch(
            updateEducation(getUserProfileData?.data?.occupation?.education)
          );
          dispatch(
            updateRelationship(
              getUserProfileData?.data?.relationship?.relationship_status
            )
          );
          dispatch(updateSmoking(getUserProfileData?.data?.habit?.smoking));
          dispatch(updateDrinking(getUserProfileData?.data?.habit?.drinking));
          dispatch(
            updateAlcoholPreference(getUserProfileData?.data?.habit?.alcohol)
          );
          dispatch(updateHeight(getUserProfileData?.data?.appearance?.height));
          dispatch(
            updateBodyType(getUserProfileData?.data?.appearance?.body_type)
          );
          dispatch(
            updateEthnicity(getUserProfileData?.data?.appearance?.ethnicity)
          );
          dispatch(
            updateEyeColor(getUserProfileData?.data?.appearance?.eye_color)
          );
          dispatch(
            updateHairColor(getUserProfileData?.data?.appearance?.hair_color)
          );
          dispatch(
            updateChildren(getUserProfileData?.data?.relationship?.children)
          );
          dispatch(
            updateShowLastActive(
              getUserProfileData?.data?.activity?.activity_display
            )
          );
          dispatch(
            updateLastActive(
              getUserProfileData?.data?.activity?.last_seen_timestamp
            )
          );
          dispatch(
            updateShowMemberSince(
              getUserProfileData?.data?.activity?.join_date_display
            )
          );
          dispatch(
            updateMemberSince(
              getUserProfileData?.data?.activity?.joined_timestamp
            )
          );
          dispatch(
            updateIsLgbtqInterested(
              getUserProfileData?.data?.relationship?.is_lgbtq_interested
            )
          );

          const personalityType =
            getUserProfileData?.data?.personality?.personality_type;
          const hobbies = getUserProfileData?.data?.habit?.hobbies;
          const starSign = getUserProfileData?.data?.personality?.star_sign;
          const fitnessLevel = getUserProfileData?.data?.habit?.fitness_level;
          const dominantOrSubmissive =
            getUserProfileData?.data?.personality?.dominant_or_submissive;
          const personalityTraits =
            getUserProfileData?.data?.personality?.personality_traits;

          let tempAboutMeList = [];
          tempAboutMeList?.push({
            name: "personality_type",
            data: personalityType?.length > 0 ? personalityType : null,
          });
          tempAboutMeList?.push({
            name: "hobbies",
            data: hobbies?.length > 0 ? hobbies : null,
          });
          tempAboutMeList?.push({
            name: "star_sign",
            data: starSign,
          });
          tempAboutMeList?.push({
            name: "fitness_level",
            data: fitnessLevel,
          });
          tempAboutMeList?.push({
            name: "dominant_or_submissive",
            data: dominantOrSubmissive,
          });
          tempAboutMeList?.push({
            name: "personality_traits",
            data: personalityTraits,
          });
          dispatch(updatePersonalityType(personalityType));
          dispatch(updateHobbies(hobbies));
          dispatch(updateFitnessLevel(fitnessLevel));
          dispatch(updateStarSign(starSign));
          dispatch(updateDominantOrSubmissive(dominantOrSubmissive));
          dispatch(updatePersonalityTraits(personalityTraits));

          dispatch(updateAboutMe(tempAboutMeList));

          dispatch(
            updateLookingForAgeRange({
              min: getUserProfileData?.data?.looking_fors?.age?.min,
              max: getUserProfileData?.data?.looking_fors?.age?.max,
            })
          );
          dispatch(
            updateLookingForLocation(
              getUserProfileData?.data?.looking_fors?.locations
            )
          );

          const temp = getUserProfileData?.data?.looking_fors?.ethnicity?.map(
            (item) => ({
              ethnicity: {
                id: item?.option?.id,
                locale: item?.option?.locale,
                display_locale: item?.option?.locale,
              },
              race: item?.sub_option
                ? {
                    id: item?.sub_option?.id,
                    locale: item?.sub_option?.locale,
                    display_locale: item?.sub_option?.locale,
                  }
                : null,
            })
          );
          dispatch(updateLookingForEthnicityRace(temp));
          dispatch(
            updateProfileStatus(getProfileStatus(getUserProfileData?.data))
          );
          dispatch(
            updatePrivatePhotoRequested(
              getUserProfileData?.data?.interaction?.private_photo_requested
            )
          );
          dispatch(
            updatePrivatePhotoApproval(
              getUserProfileData?.data?.interaction?.private_photo_approval
            )
          );
          dispatch(
            updateRequestedMyPrivatePhoto(
              getUserProfileData?.data?.interaction?.requested_my_private_photo
            )
          );
          dispatch(
            updateApprovedMyPrivatePhoto(
              getUserProfileData?.data?.interaction?.approved_my_private_photo
            )
          );
          dispatch(
            updateIsOnline(getUserProfileData?.data?.activity?.is_online)
          );
          dispatch(
            updateIsBlocked(
              getUserProfileData?.data?.interaction?.is_blocked ? true : false
            )
          );
          dispatch(
            updateIsSilentInteraction(
              getUserProfileData?.data?.interaction?.should_silent_interactions
            )
          );
          dispatch(
            updateIsFavorited(
              getUserProfileData?.data?.interaction?.is_favorited
            )
          );
          dispatch(
            updateIsCallEnabled(
              getUserProfileData?.data?.private_call_settings?.enable_calls &&
                getUserProfileData?.data?.private_call_settings
                  ?.system_private_calls_enabled
            )
          );
          dispatch(
            updateIsSugarbaby(
              getUserProfileData?.data?.role?.toUpperCase() ===
                userConst.userRole.sugarbaby ||
                getUserProfileData?.data?.role?.toUpperCase() ===
                  userConst.userRole.maleSugarbaby
            )
          );
          dispatch(updateRole(getUserProfileData?.data?.role));

          // Not needed in dialog
          // if (getUserProfileData?.data?.live_stream?.live_status === "live") {
          //   const liveObj = {
          //     id: getUserProfileData?.data?.id,
          //     idInt: getUserProfileData?.data?.id_int,
          //     username: getUserProfileData?.data?.username,
          //     channelId: getUserProfileData?.data?.live_stream?.channel_id,
          //   };
          //   dispatch(updateUserIsLiveData(liveObj));
          //   dispatch(updateUserIsLiveDialog(true));
          // }
          break;
        case -1:
          break;
        default:
          break;
      }
    } else if (getUserProfileError) {
      // dispatch(updateUserProfileDialog(false));
    }
  }, [
    getUserProfileFetching,
    getUserProfileLoading,
    getUserProfileSuccess,
    getUserProfileError,
  ]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateUserProfileDialog(false));
  };

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    emblaApi?.scrollTo(newValue);
  };

  // Utility Functions
  const calculateAge = (birthYear, birthMonth, birthDate) => {
    const birth = moment(
      `${birthYear}-${birthMonth}-${birthDate}`,
      "YYYY-MM-DD"
    );
    const now = moment();
    const age = now.diff(birth, "years");
    return age;
  };

  return (
    <Dialog
      className={`custom-top-radius20-dialog`}
      fullScreen
      open={userProfileDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <ComponentNavbar
        userProfileType={profileConst.userProfileType.dialog}
        showMoreOptionsButton={false}
      />

      {getUserProfileFetching || getUserProfileLoading ? (
        <Spinner />
      ) : getUserProfileSuccess || !shouldCallApi ? (
        <div id="user-profile-dialog">
          <div
            className={`max-width-container ${
              isMobile ? "" : "profile-padding-container"
            }`}
          >
            {!isMobile && (
              <div className={`desktop-view profile-padding-container`}>
                <TopUserContainer
                  userProfileType={profileConst.userProfileType.dialog}
                />

                <BottomUserContainer
                  userProfileType={profileConst.userProfileType.dialog}
                />
              </div>
            )}

            {isMobile && (
              <div className="mobile-view">
                <PhotoInfo />

                <BasicInfo />

                <div className="tab-container">
                  <Tabs
                    className={`custom-tabs add-padding`}
                    value={profileTab}
                    onChange={onTabChange}
                    TabIndicatorProps={{
                      style: { backgroundColor: "#710d0d" },
                    }}
                    variant={"fullWidth"}
                    scrollButtons={false}
                  >
                    <Tab
                      className="user-profile-tab"
                      label={t("profile.profile")}
                    />
                    {(getUserAioData?.data?.modules?.live_streaming_enabled ||
                      getUserAioData?.data?.modules?.private_calls_enabled) && (
                      <Tab
                        className="user-profile-tab"
                        label={t("profile.achievements")}
                      />
                    )}
                  </Tabs>

                  <div ref={emblaRef} className="embla">
                    <div className="embla__container">
                      <div className="embla__slide">
                        {profileTab === 0 && (
                          <div>
                            <TermsOfRelationship />

                            <ProfileAboutMe />

                            <ProfileLookingFor />

                            <ProfileDatingStyle />

                            <ProfileFinancialInformation />

                            <ProfileAlcoholPreference />

                            <ProfileMoreAboutMe />
                          </div>
                        )}
                      </div>

                      {(getUserAioData?.data?.modules?.live_streaming_enabled ||
                        getUserAioData?.data?.modules
                          ?.private_calls_enabled) && (
                        <div className="embla__slide">
                          {profileTab === 1 && <ProfileAchievements />}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <ProfileActionButtons
                  userProfileType={profileConst.userProfileType.dialog}
                />
              </div>
            )}
          </div>
        </div>
      ) : getUserProfileError ? (
        <div id="user-profile-error-dialog">
          <div className="max-width-container">
            <div className="profile-error-image-container">
              <img
                className="profile-error-image"
                src={getAsset("emptyLiveList")}
                alt="empty"
              />
            </div>

            <div className="profile-not-found-label">
              {t("profile.profile_not_found")}
            </div>

            <div className="profile-not-found-body">
              {getUserProfileErrorData?.data?.message}
            </div>
          </div>
        </div>
      ) : null}
    </Dialog>
  );
};

export default UserProfileDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
